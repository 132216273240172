<template>
    <SlidesComp/>
    <HomeComp/>
</template>
<script>

import HomeComp from '@/components/mobile/homeComp.vue';
import SlidesComp from '@/components/mobile/slidesComp.vue';
import { useStore } from 'vuex';
import { computed } from 'vue';

// import { ref, onMounted, onBeforeUnmount } from 'vue';
// import router from '@/tools/router';

export default {
    components: {
    SlidesComp,
    HomeComp
},
setup() {
        const store = useStore();
        const messages = computed(() => store.state.messages);
        const currentLanguage = computed(() => store.state.language);
        const langconf = (key) => {
            return messages.value[currentLanguage.value][key];
        };
        return {
            langconf,
        };
    },

};

</script>
<style scoped>
</style>