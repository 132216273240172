import { createStore } from 'vuex';
import en from '../tools/languages/en';
import zh from '../tools/languages/zh';

const savedLanguage = localStorage.getItem('language');

const store = createStore({
  state: {
    language: savedLanguage || 'zh',
    messages: {
      en,
      zh,
    },
  },
  mutations: {
    setLanguage(state, lang) {
      state.language = lang;
      localStorage.setItem('language', lang);
    },
    clearLanguage(state) {
      state.language = 'zh';
      localStorage.removeItem('language');
    },
  },
});

export default store;
