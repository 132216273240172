<template>
    <div class="news">
      <div class="newstit">
        <div class="newskezi">
          <div class="newstittext">{{ langconf('新闻动态') }}</div>
          <div class="newsimg">
            <img src="../../assets/new/newsbg.webp" alt="">
          </div>
        </div>
      </div>
      <div class="news-list">
        <span class="newtit" style="margin-left: 72px; font-size: larger;">标题</span>
        <span class="newtime" style="font-size: larger;">时间</span>
    </div>
      <div class="news-list">
        <ul class="newsmenu">
          <li v-for="newsItem in paginatedNews" :key="newsItem.id" @click="selectnew(newsItem.id)" class="newconf">
            <div class="newtit">{{ newsItem.title }}</div>
            <div class="newtime">{{ newsItem.data }}</div>
          </li>
        </ul>
        <div class="pagination">
          <button @click="prevPage" :disabled="currentPage === 1">{{ langconf('上一页') }}</button>
          <button @click="gotoPage(pageNumber)" v-for="pageNumber in visiblePageNumbers" :key="pageNumber"
            :class="{ active: pageNumber === currentPage }">{{ pageNumber }}</button>
          <button @click="nextPage" :disabled="currentPage === totalPages">{{ langconf('下一页') }}</button>
        </div>
      </div>
    </div>
  </template>
    
  <script>
  import { useStore } from 'vuex';
  import router from '../../tools/router';
  import { computed } from 'vue';
  
  export default {setup() {
          const store = useStore();
          const messages = computed(() => store.state.messages);
          const currentLanguage = computed(() => store.state.language);
  
          const selectnew = (newid) => {
              if (newid == 1) {
                router.push('/mnew1')
              }
          }
  
          const langconf = (key) => {
              return messages.value[currentLanguage.value][key];
          };
          return {
              langconf,
              selectnew,
          };
      },
    data() {
      return {
        news: [
          { id: 1, title: '上欧科技完成天使轮融资，助力泛半导体产业智造升级', data: '2024-02-29' },
        ],
        perPage: 10, // 每页显示的新闻数量
        currentPage: 1, // 当前页码
        maxVisiblePages: 5, // 可见的数字页按钮数量
      };
    },
    computed: {
      paginatedNews() {
        const startIndex = (this.currentPage - 1) * this.perPage;
        const endIndex = startIndex + this.perPage;
        return this.news.slice(startIndex, endIndex);
      },
      totalPages() {
        return Math.ceil(this.news.length / this.perPage);
      },
      visiblePageNumbers() {
        const halfMaxVisiblePages = Math.floor(this.maxVisiblePages / 2);
        let startPage = this.currentPage - halfMaxVisiblePages;
        let endPage = this.currentPage + halfMaxVisiblePages;
  
        if (startPage < 1) {
          startPage = 1;
          endPage = Math.min(this.totalPages, this.maxVisiblePages);
        }
  
        if (endPage > this.totalPages) {
          endPage = this.totalPages;
          startPage = Math.max(1, this.totalPages - this.maxVisiblePages + 1);
        }
  
        return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
      },
    },
    methods: {
      prevPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      },
      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      },
      gotoPage(pageNumber) {
        this.currentPage = pageNumber;
      },
    },
    watch: {
      // 监听 currentPage 变化，每当切换页码时触发
      currentPage() {
        // 将页面滚动位置设置到最顶部
        window.scrollTo(0, 0);
      },
    },
  };
  </script>
  <style scoped>
  .newtit {
    float: left;
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
  }
  
  .newtime {
    float: right;
    height: 100%;
    width: 30%;
    display: flex;
    align-items: center;
  }
  
  .news {
    margin: 0px auto;
  }
  
  .newskezi {
    width: 100%;
    margin: 0 auto;
  }
  
  .newstit {
    position: relative;
    width: 100%;
    margin: 0 auto;
    background-image: linear-gradient(to bottom, #838383, #ffffff);
  }
  
  .newstittext {
    position: relative;
    float: left;
    margin-left: 15px;
    font-size: 26px;
    top: 110px;
  }

  .newsimg img {
    width: 70%;
    margin: 10px 0px 0px 103px;
  }
  
  .news-list {
    width: 100%;
    margin: 0 auto;
  }
  
  .newsmenu {
    list-style: none; 
    padding: 20px;
  }
  
  .newconf {
    height: 80px;
    border-bottom: 1px solid #c5c5c5;
    cursor: pointer;
    transition: 0.3s;
  }
  
  .pagination {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
  
  .pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
    background-color: #fff;
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 4px;
  }
  
  .pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  .pagination button.active {
    background-color: #0056b3;
  }
  </style>
      