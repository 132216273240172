import { createApp } from 'vue'
import App from './App.vue'
import store from './store/store'
import router from './tools/router'

const app = createApp(App)


app.use(router);
app.provide('store', store);


app.mount('#app')

window.addEventListener('beforeunload', () => {
    store.dispatch('clearLanguage');
  });