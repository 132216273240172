<template>
    <div class="contusview">
        <div class="contustitimgkezi">
            <div class="contustitimg">
                <img src="../../assets/contactus/contactusbg.webp" alt="公司图片">
            </div>
        </div>
        <div class="kezi">
            <div class="contustext">
                <p>{{ langconf('公司介绍') }}</p>
            </div>
            <div class="contusab">
                <div class="wenhuaab">
                    <span>
                        <img src="../../assets/contactus/cultural.webp" alt="文化理念">
                    </span>
                    <span class="wenhuatext">
                        <h1>{{ langconf('文化理念') }}</h1>
                        <p>{{ langconf('文化理念标题') }}<br><br>
                            {{ langconf('文化理念目的') }}<br><br>
                            {{ langconf('文化理念详情') }}
                        </p>
                    </span>
                </div>
                <div class="shehuiab">
                    <span class="shehuitext">
                        <h1>{{ langconf('社会贡献') }}</h1>
                        <p>{{ langconf('社会贡献题目') }}<br><br>
                            {{ langconf('社会贡献详情') }}
                        </p>
                    </span>
                    <span>
                        <img src="../../assets/contactus/society.webp" alt="社会贡献" style="object-fit: cover;">
                    </span>
                </div>
            </div>
            <div class="contactsussocial">
                <div style="color: #ffffff;">
                    <!-- <img src="../../assets/contactus/phone.webp" alt="电话"><br> -->
                    <div>{{ langconf('电话') }}</div>
                    <span>中国 +86 0572-2025860</span>
                </div>
                <div>
                    <img src="../../assets/contactus/mail.webp" alt="邮箱"><br>
                    <div>{{ langconf('电子邮件') }}</div>
                    <span>service@hio.technology</span>
                </div>
                <div style="color: #ffffff;">
                    <!-- <img src="../../assets/contactus/community.webp" alt="社区"><br> -->
                    <div>{{ langconf('社区') }}</div>
                    <span>进入上欧社区 ></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
export default {
    setup() {
        const store = useStore();
        const messages = computed(() => store.state.messages);
        const currentLanguage = computed(() => store.state.language);
        const langconf = (key) => {
            return messages.value[currentLanguage.value][key];
        };
        return {
            langconf,
        };
    },
};

</script>
<style scoped> 

.contustitimgkezi::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background: linear-gradient(to bottom, rgb(0, 0, 0), rgba(64, 12, 12, 0));
  opacity: 0.5;
  pointer-events: none;
  z-index: 10;
}

.contactsussocial div div{
    margin: 20px auto;
}

.contactsussocial{
    width: 680px;
    margin: 100px auto 200px auto;
    display: flex;
    text-align: center;
    justify-content: space-between;

}

.contusab {
    width: 1280px;
    margin: 0 auto;
    margin-top: 100px;
    text-align: center;
}

.shehuiab {
    width: 100%;
    margin-top: 80px;
    display: flex;
    text-align: right;
}

.shehuiab span {
    width: 50%; 
    height: 480px;
    overflow: hidden;
}
.shehuitext {
    width: 50%;
    text-align: right;
    background-color: #A3C0EC;
}


.wenhuatext {
    width: 50%;
    height: 400px;
    text-align: left;
    background-color: #A3C0EC;
}

.shehuitext h1,
.shehuitext p {
    margin: 40px 40px 0 0;
}

.shehuitext p {
    width: 80%;
    line-height: 30px;
    float: right;
}

.wenhuatext h1,
.wenhuatext p {
    margin: 40px 0 0 40px;
}

.wenhuatext p {
    width: 80%;
    line-height: 30px;
}

.wenhuaab {
    width: 100%;
    display: flex;
    text-align: left;
}

.wenhuaab span {
    width: 50%;
    height: 480px;
}

.contusab span img{
    width: 100%;
    height: 100%;
}

.kezi {
    margin: 0 auto;
    width: 1280px;
    position: relative;
}

.contusview {
    position: relative;
    width: 100%;
    justify-content: center;
    margin: -80px auto;
}

.contustext {
    width: 1280px;
    margin-top: 100px;

    text-align: center;
}

.contustext p {
    font-size: 18px;
    line-height: 30px;
    font-weight: 200;
    text-align: left;
}

.contustitimg {
    width: 100%;
    margin: 0 auto;
    position: relative;
    height: 480px;
    overflow: hidden;
    display: flexbox;
    justify-content: center;

}

.contustitimg img {
    margin: -750px auto 0 auto;
    margin-left: 50%;
    transform: translateX(-50%);
    width: 2560px;
}
</style>