<template>
    <div class="contusview">
        <div class="contustitimgkezi">
            <div class="contustitimg">
                <img src="../../assets/contactus/contactusbg.webp" alt="公司图片">
            </div>
        </div>
        <div class="kezi">
            <div class="contustext">
                <p>{{ langconf('公司介绍') }}</p>
            </div>
            <div class="contusab">
                <div class="wenhuaab">
                    <span>
                        <img src="../../assets/contactus/cultural.webp" alt="文化理念">
                    </span>
                    <span class="wenhuatext">
                        <h1>{{ langconf('文化理念') }}</h1>
                        <p>{{ langconf('文化理念标题') }}<br><br>
                            {{ langconf('文化理念目的') }}<br><br>
                            {{ langconf('文化理念详情') }}
                        </p>
                    </span>
                </div>
                <div class="shehuiab">
                    <span class="shehuitext">
                        <span>
                            <img src="../../assets/contactus/society.webp" alt="社会贡献"
                                style="height: 220px; object-fit: cover;">
                        </span>
                        <h1>{{ langconf('社会贡献') }}</h1>
                        <p>{{ langconf('社会贡献题目') }}<br><br>
                            {{ langconf('社会贡献详情') }}
                        </p>
                    </span>

                </div>
            </div>
            <div class="contactsussocial">
                <!-- <div class="m-function">
                    <img src="../../assets/contactus/phone.webp" alt="电话"><br>
                    <div>电话</div>
                    <div>中国 +86 400-700-0303</div>
                </div> -->
                <div class="m-function">
                    <img src="../../assets/contactus/mail.webp" alt="邮箱"><br>
                    <div>{{ langconf('电子邮件') }}</div>
                    <div>service@hio.technology</div>
                </div>
                <!-- <div class="m-function">
                    <img src="../../assets/contactus/community.webp" alt="社区"><br>
                    <div>社区</div>
                    <div>进入激光雷达社区 ></div>
                </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
export default {
    setup() {
        const store = useStore();
        const messages = computed(() => store.state.messages);
        const currentLanguage = computed(() => store.state.language);
        const langconf = (key) => {
            return messages.value[currentLanguage.value][key];
        };
        return {
            langconf,
        };
    },
};
 
</script>
<style scoped>
.contustitimgkezi::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: linear-gradient(to bottom, rgb(0, 0, 0), rgba(64, 12, 12, 0));
  opacity: 0.5;
  pointer-events: none; /* 防止遮挡内容的交互 */
}

.contactsussocial {
    text-align: center;
    
}

.m-function {
    margin: 20px auto;
}

.m-function div{
    font-size: 16px;

}

.m-function img {
    width: 40px;
}

.shehuitext h1 {
    font-size: 24px;
}

.shehuitext {
    overflow: hidden;  
    height: 250px;
}

.shehuitext image {
    height: 250px;
}

.shehuiab p {
    font-size: 16px;
    line-height: 1.5;
}

.shehuiab img {
    width: 100%;
    height: 100px;
}

.wenhuatext h1 {
    font-size: 24px;
}

.wenhuatext p {
    font-size: 16px;
    line-height: 1.5;
}

.wenhuaab img {
    width: 100%;
}

.contustext p {
    font-size: 16px;
    line-height: 1.5;
    
}

.contustext {
    margin-top: 20px;
}

.contustitimg {
    height: 150px;
    overflow: hidden;
}

.contustitimg img {
    width: 100%;
    
}

.kezi {
    width: 90%;
    margin: 0 auto;
}
</style>