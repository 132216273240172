<template>
    <div class="news">
        <div class="newstit">
          <div class="newskezi">
            <div class="newstittext">{{ langconf('新闻动态') }}</div>
            <div class="newsimg">
              <img src="../../assets/new/newsbg.webp" alt="">
            </div>
          </div>
        </div>
        <div class="newtext">
            <div class="title"><h1>上欧科技完成天使轮融资，助力泛半导体产业智造升级</h1></div>
            <div><img src="../../assets/news/01_01/01_01.0.webp" class="img0"></div>
            <div>
                <p>
                  近日，上欧科技（湖州）有限公司（简称“上欧科技”）顺利完成了其天使轮融资，融资的领投方为湖州启真湖智创业投资合伙企业。本轮融资彰显了资本市场对上欧科技在光学测量与检测领域的技术研发实力及未来发展前景的高度认可。此次融资所募得的资金将重点投入于泛半导体制造中至关重要的光学测量与检测装备的研发工作，旨在推动技术创新的快速落地，加速产品从实验室走向市场的步伐，并积极拓展新兴市场，以实现更广泛的行业应用。上欧科技将以此为契机，着力构建起一个融合前沿科学研究与产业化应用于一体的新型生态体系，致力于提升我国泛半导体产业链自主创新能力，为行业的高质量发展注入强劲动力。
                </p>
              </div>
            <div><img src="../../assets/news/01_01/01_01.1.webp" class="img1"></div>
            <div><h1>关于上欧科技</h1></div>
            <div><p>
                上欧科技（湖州）有限公司坐落于浙江省湖州市南太湖新区，是一家专注于泛半导体制造领域的智能光学测量与检测装备的研发与生产的企业。公司与浙江大学湖州研究院和湖州市智能光电高端装备重点实验室紧密合作，致力于推动产学研的深度融合。上欧科技专注于国产自主智能光学成像、检测和量测设备与仪器的创新，推动科研成果的广泛应用和转化。公司以市场需求为导向，秉承开放合作的理念，力争解决行业难题，推动中国精密光学检测行业的发展，同时培育高素质的智能光学研发人才，提供领先的产品和技术服务。
    上欧科技的技术核心涵盖高速结构光超分辨率显微成像技术、高精度波前探测技术、计算成像技术及计算机视觉和人工智能技术。这些技术广泛应用于精密制造产品的在线质量检测和关键尺寸量测，特别是在无图晶圆、有图晶圆、显示面板、手机镜片、人工晶体和晶圆级微光学元件等领域。
    公司创始人为浙江大学“百人计划（平台类）”研究员，拥有荷兰代尔夫特理工大学博士学位，曾在荷兰柔性光学和英国帝国理工学院工作，参与了欧盟资助的“下一代半导体量检测技术”项目等重大科研项目。上欧科技拥有一支由多学科专家组成的硕博士研发团队，涵盖光学设计、精密结构设计、精密运动控制、计算成像、高速图像处理、机器视觉与人工智能算法等领域。团队成员多毕业于全球顶尖学府，如英国帝国理工学院、美国普渡大学和荷兰代尔夫特理工大学，许多成员曾在荷兰ASML、美国Meta、华为等国际知名企业任职，具备丰富的科研和工程实践经验。研发团队团结一心，秉承“产业报国”的信念，致力于成为推动中国半导体装备自主化的关键力量。
    公司联合创始人是比利时安特卫普管理学院博士，哥伦比亚商学院投融资课程导师，曾在伦敦摩根·斯坦利并购部门工作。企业管理和技术转化团队由来自清华大学、复旦大学和美国哥伦比亚大学等国内外知名学府的硕博士组成，他们具备深厚的企业管理经验和金融知识，致力于将公司的技术优势转化为商业价值，为公司的发展提供全面的支持和服务，旨在以卓越的专业能力和丰富的管理经验为上欧科技的长期发展奠定坚实基础。
    展望未来，上欧科技计划构建以泛半导体领域为基础的智能光学检测装备产业链，与科研机构及产业界保持紧密的合作，促进更精密装备的市场化，以推动行业的全面进步。</p>
            </div>
            <div><h1>产品与应用概览</h1></div>
            <div><img src="../../assets/news/01_01/01_01.2.webp" class="img2"></div>
            <div><h1>晶圆亚微米级缺陷在线检测解决方案</h1></div>
            <div>
                <p>
                  上欧科技推出了面向有图和无图平面器件的亚微米级缺陷在线检测解决方案，旨在提供高精度的在线光学检测装备。该方案结合了自主研发的二维成像模块和三维成像技术，实现了在明场和暗场光学成像基础上的高速扫描，以高速捕捉大视场的高分辨率图像数据。三维成像部分通过结构光成像技术和波前传感技术结合，实现了器件表面的纳米级高精度三维成像。超分辨率缺陷识别模块使用了无透镜检测技术和计算光学，显著缩小了成像系统体积，突破了传统成像系统的物理限制。缺陷识别与分类软件部署在自建的高算力并行计算设备上，大幅提高了图像优化算法和深度学习的推理速度，实现了高通量的图像数据处理，满足了在线生产的节奏要求。该方案已在晶圆加工、镀膜、匀胶、刻蚀和切片等环节实现缺陷检测，并在显示面板和精密光学元件制造等生产线上得到部署应用，为多个行业提供了针对质量控制和缺陷检测的高质量、定制化解决方案。 
                </p>
            </div>
            <div><h1>高精度波前测量解决方案</h1></div>
            <div>
                <p>
                  上欧科技为晶圆级光学元件制造行业（包括超透镜、增强现实全息光栅、微透镜、人工晶体等）提供高精度波前测量解决方案。该方案运用了高精度的Shack-Hartmann波前传感技术和先进的相位计算恢复方法，以精确评估晶圆级三维光学器件的表面形态、像差和关键光学性能参数。通过深度融合精密光学计量学、高性能数据处理算法与先进计算架构，该方案提供了快速、高精度的测量结果，确保在大规模制造环境中对光学元件的精确品质控制和实时质量监控。这一方案特别适用于评估复杂光学元件的细微特征，在面对复杂的设计和制造挑战时，为精密光学元件制造商提供了有效的支持，确保这些元件在高性能应用中发挥最佳性能。
                </p>
              </div>
        </div>
    </div>
    </template>
    <script>
    import { useStore } from 'vuex';
    
    import { computed } from 'vue';
    export default {setup() {
        const store = useStore();
        const messages = computed(() => store.state.messages);
        const currentLanguage = computed(() => store.state.language);
        const langconf = (key) => {
            return messages.value[currentLanguage.value][key];
        };
        return {
            langconf,
        };
    
    }
    
    }
    
    </script>
    <style scoped>
    .newtext {
        width: 90%;
        margin: 100px auto 100px auto;
    }
    .img0,
    .img1,
    .img2
    { 
      width: 80%;
      margin: auto;
    }
    
    .newtext p {
      font-size: 20px;
        line-height: 30px;
        font-weight: 400;
        color: #000000;
        text-align: left;
    }
    .newtext h1 {
        width: 90%;
        margin: 20px auto;
    }
    
    .newtext div {
      margin: 0 auto;
      width: 100%;
      text-align: center;
    }
    
    .news {
      margin: -80px auto 0 auto;
    }
    .newtit {
    float: left;
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
  }
  
  .newtime {
    float: right;
    height: 100%;
    width: 30%;
    display: flex;
    align-items: center;
  }
  
  .news {
    margin: 0px auto;
  }
  
  .newskezi {
    width: 100%;
    margin: 0 auto;
  }
  
  .newstit {
    position: relative;
    width: 100%;
    margin: 0 auto;
    background-image: linear-gradient(to bottom, #838383, #ffffff);
  }
  
  .newstittext {
    position: relative;
    float: left;
    margin-left: 15px;
    font-size: 26px;
    top: 110px;
  }

  .newsimg img {
    width: 70%;
    margin: 10px 0px 0px 103px;
  }
  
    
    </style>