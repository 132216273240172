<template>
    <div class="recruit">
        <div class="recruitbg">
            <div class="recruittext">
                <div>{{ langconf('招聘题目') }}</div>
                <p>{{ langconf('招聘理念') }}</p>
            </div>
            <img src="../../assets/recruitment/RecruitmentBg.webp" alt="">
        </div>
        <div style="width: 90%; margin: 0 auto;">
            <div class="recruittit">
                <div class="recruitconf">
                    <h1>{{ langconf('找到团队') }}</h1>
                </div>
                <div class="teamconf">
                    <div class="teammenu">
                        <div class="rdteam">
                            <div class="teamname">
                                {{ langconf('研发团队') }}
                            </div>
                            <a href="" class="hreftozhi1">{{ langconf('在招职位') }} ></a>
                        </div>
                        <div class="fcteam">
                            <div class="teamname">
                                {{ langconf('职能团队') }}
                            </div>
                            <a href="" class="hreftozhi2">{{ langconf('在招职位') }} ></a>
                        </div>
                        <div class="saleteam">
                            <div class="teamname">
                                {{ langconf('市场销售团队') }}
                            </div>
                            <a href="" class="hreftozhi3">{{ langconf('在招职位') }} ></a>
                        </div>
                        <div class="desteam">
                            <div class="teamname">
                                {{ langconf('设计团队') }}
                            </div>
                            <a href="" class="hreftozhi4">{{ langconf('在招职位') }} ></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
export default {
    setup() {
        const store = useStore();
        const messages = computed(() => store.state.messages);
        const currentLanguage = computed(() => store.state.language);
        const langconf = (key) => {
            return messages.value[currentLanguage.value][key];
        };
        return {
            langconf,
        };
    },
};

</script>
<style scoped>
.hreftozhi1,
.hreftozhi2,
.hreftozhi3,
.hreftozhi4 {
    float: left;
    margin-left: 10px;
    margin-top: 90px;
    font-size: 12px;
    color: #3A8CFC;
}


.hreftozhi4 {
    float: left;
    margin-left: 10px;
    font-size: 12px;
}

.teamname {
    margin-top: 10px;
    margin-left: 10px;
}

.teamconf {
    width: 100%;
    margin: 0 auto;
}


.desteam {
    height: 150px;
    width: 150px;
    background-color: #F1F1F1;
    margin: 10px auto;
    border-radius: 10px;
}

.saleteam {
    height: 150px;
    width: 150px;
    background-color: #F1F1F1;
    margin: 10px auto;
    border-radius: 10px;
}

.rdteam {
    height: 150px;
    width: 150px;
    background-color: #F1F1F1;
    margin: 10px auto;
    border-radius: 10px;
}

.fcteam {
    height: 150px;
    width: 150px;
    background-color: #F1F1F1;
    margin: 10px auto;
    border-radius: 10px;
}

.teammenu {
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
}

.recruitbg {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.recruitbg img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    filter: brightness(0.5);
}

.recruittext {
    top: 100px;
    position: absolute;
    z-index: 1;
    margin: 0 auto;
    color: #ffffff;
}

.recruittext div {
    font-size: 24px;
    text-align: center;

}

.recruittext p {
    text-align: center;
    font-size: 12px;
}

.recruitconf {
    text-align: center;
}

.recruitconf h1 {
    font-size: 24px;
}
</style>