<template>
  <menuComp v-show="!ism" />
  <mmenuComp v-show="ism" />
  <RouterView /> 
  <footerComp v-show="!ism" />
  <mfooterComp v-show="ism" />
</template>

<script>
import { ref } from 'vue';
import footerComp from './components/pc/footerComp.vue';
import mfooterComp from './components/mobile/footerComp.vue';
import menuComp from './components/pc/menuComp.vue';
import mmenuComp from './components/mobile/menuComp.vue';


export default {
  components: {
    menuComp,
    mmenuComp,
    footerComp,
    mfooterComp,
  }, 

  setup() {

    const ism = ref(false)
    if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
    ism.value = true
    } 

    return {
      ism,
    };
  }
}

</script>

<style>
@import './assets/font/font.css';

body {
  font-family:"Microsoft YaHei",Arial,Helvetica,sans-serif;
}

.pcmenu.show {
  display: none;
}
</style>
