<template>
    <div class="application">
        <div class="applititimg">
            <div class="appimgkezi">
                <img src="../../assets/application/applicationbg.webp" alt="全家桶">
            </div>
        </div>
        <div class="appliconf">
            <h1>{{ langconf('行业领域') }}</h1>
            <div class="tmtdomain">
                <div class="YAG">
                    <img src="../../assets/application/semiconductor.webp" alt="半导体" class="YAGimg">
                    <div>
                        <span>{{ langconf('半导体') }}</span>
                        <p>{{ langconf('半导体质量在线检测') }}</p>
                    </div>
                </div>
                <div class="SHOW">
                    <img src="../../assets/application/display.webp" alt="显示面板" class="SHOWimg">
                    <div>
                        <span>{{ langconf('显示面板') }}</span>
                        <p>{{ langconf('显示面板缺陷在线监测') }}</p>
                    </div>
                </div>
                <div class="GUANG">
                    <img src="../../assets/application/photovoltaic.webp" alt="光伏" class="GUANGimg">
                    <div>
                        <span>{{ langconf('光伏') }}</span>
                        <p>{{ langconf('光伏面板缺陷在线监测') }}</p>
                    </div>
                </div>
                <div class="medicine">
                    <img src="../../assets/application/medicine.webp" alt="医学" class="medicineimg">
                    <div>
                        <span>{{ langconf('医学') }}</span>
                        <p>{{ langconf('细胞检测') }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="appliconf">
            <h1>{{ langconf('最近更新') }}</h1>
            <div class="nearupdate">
                <div>
                    <img src="../../assets/application/Adhesive_inspection.webp" alt="GI">
                    <div>HIO GI-V1 {{ langconf('点胶在线检测') }}</div>
                </div>
                <div>
                    <img src="../../assets/application/6inch_wafer.webp" alt="WI">
                    <div>HIO WI-V1 {{ langconf('有图晶圆表面缺陷快速检测') }}</div>
                </div>
                <div>
                    <img src="../../assets/application/MLA.webp" alt="WLI">
                    <div>HIO WLI-V1 {{ langconf('微透镜阵列检测') }}</div>
                </div>
                <div>
                    <img src="../../assets/application/Cell_monitoring.webp" alt="">
                    <div>HIO VWI-V1 {{ langconf('血细胞三维形貌测量') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
    setup() {
        const store = useStore();
        const messages = computed(() => store.state.messages);
        const currentLanguage = computed(() => store.state.language);
        const langconf = (key) => {
            return messages.value[currentLanguage.value][key];
        };
        return {
            langconf
        }
    }

}

</script>
<style scoped>
.application {
    margin: -80px auto 0 auto;
}

.medicine,
.GUANG,
.SHOW,
.YAG {
    height: 400px;
    position: relative;
}

.medicine div,
.GUANG div,
.SHOW div,
.YAG div {
    text-align: left;
    position: absolute;
    left: 50px;
    bottom: 20px;
    z-index: 9;
    color: #ffffff;
}

.medicine div span,
.GUANG div span,
.SHOW div span,
.YAG div span {
    font-size: 38px;
}

.medicine div p,
.GUANG div p,
.SHOW div p,
.YAG div p {
    font-size: 20px;
}

.nearupdate div {
    height: 450px;
    background-color: rgb(104, 104, 104);
}

.nearupdate div img {

    height: 90%;
}

.tmtdomain img {
    filter: brightness(0.5);
    position: relative;
    z-index: 2;
    width: 100%;
    height: 400px;

}

.tmtdomain {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin: 0 auto;
    width: 1280px;
    text-align: center;
}

.nearupdate {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
}

.nearupdate div div {
    color: #ffffff;
    position: relative;
    height: 40px;
    align-items: center;
    display: flex;
    margin-left: 20px;
}

.appliconf {
    width: 1280px;
    margin: 100px auto;
}

.appliconf img {
    width: 100%;
    object-fit: contain;
    object-fit: cover;
}

.applititimg {
    width: 100%;
    height: 600px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.applititimg::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 480px;
    background: linear-gradient(to bottom, rgb(0, 0, 0), rgba(64, 12, 12, 0));
    opacity: 0.5;
    pointer-events: none;
    /* 防止遮挡内容的交互 */
}

.appimgkezi {
    width: 2560px;
    height: 480px;
    margin: 0 auto;
    align-items: center;
    display: flex;
}

.appimgkezi img {
    width: 100%;
    margin: 0 auto;
}
</style>
