<template>
    <div>
        <div>
            <div class="m-prowi" @click="to('wi')">
                <div class="m-protitbox">
                    <div class="m-protit">
                        {{ langconf('WI') }}
                    </div>
                    <div class="m-pros">
                        HIO WI-V1
                    </div>
                    <div class="m-prohref">{{ langconf('了解更多') }} ></div>
                </div>
                <div class="m-proimgbox">
                    <img src="../../assets/wi/WI.webp" class="m-wiimg">
                </div>
            </div>
            <div class="m-prowi" @click="to('gi')">
                <div class="m-protitbox">
                    <div class="m-protit">
                        {{ langconf('GI') }}
                    </div>
                    <div class="m-pros">
                        HIO GI-V1
                    </div>
                    <div class="m-prohref">{{ langconf('了解更多') }} ></div>
                </div>
                <div class="m-proimgbox">
                    <img src="../../assets/gi/GI.webp" class="m-wiimg">
                </div>

            </div>
            <div class="m-prowi" @click="to('vwi')">
                <div class="m-protitbox">
                    <div class="m-protit">
                        {{ langconf('VWI') }}
                    </div>
                    <div class="m-pros">
                        {{ langconf('桌面级') }} / {{ langconf('工业级') }} / {{ langconf('人工晶体专用') }}
                    </div>
                    <div class="m-prohref">{{ langconf('了解更多') }} ></div>
                </div>
                <div class="m-proimgbox">
                    <img src="../../assets/vwi/VWI.webp" class="m-wiimg">
                </div>

            </div>
        </div>
        <div class="m-aboutus">
            <div class="m-abouttit">
                {{ langconf('关于我们') }}
            </div>
            <div class="m-fenge"></div>
            <div class="m-aboutconf">
                {{ langconf('关于我们内容') }}
                </div>
        </div>
        <div class="m-friends">
            <div class="m-abouttit">
                {{ langconf('合作伙伴') }}
            </div>
            <div class="m-fenge"></div>
            <img src="../../assets/home/partnership.webp" alt="">
        </div>
    </div>
</template>
<script>
import router from '@/tools/router';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
    setup() {
        const store = useStore();
        const messages = computed(() => store.state.messages);
        const currentLanguage = computed(() => store.state.language);
        const langconf = (key) => {
            return messages.value[currentLanguage.value][key];
        };
        return {
            langconf,
        }

    },
    methods: {

        to(name) {
            if (name == 'wi') {
                router.push('/product-WI')
            }
            if (name == 'gi') {
                router.push('/product-GI')
            }
            if (name == 'vwi') {
                router.push('/product-VWI')
            }
        }
    }
}


</script>
<style scoped>
.m-proimgbox {
    margin: 0 auto;
    width: 300px;
}

.m-friends {
    width: 90%;
    margin: 20px auto;
}

.m-friends img {
    width: 100%;
}


.m-aboutconf {
    line-height: 1.5;
}

.m-abouttit {
    font-size: 22px;
    font-weight: 700;
}

.m-aboutus {
    width: 90%;
    margin: 40px auto;
}

.m-fenge {
    height: 3px;
    width: 60%;
    background-color: #3B66FF;
    margin: 15px auto 15px 0;
}

.m-wiimg {
    margin-top: 30px;
    width: 300px;
    margin: 30px auto;
}

.m-prohref {
    margin-top: 8px;
    color: #3A8CFC;
}

.m-protit {
    position: relative;
    text-align: center;
    top: 20px;
    font-size: 24px;
    font-weight: 700;
}

.m-pros {
    margin-top: 30px;
    text-align: center;
}

.m-protitbox {
    width: 100%;
    text-align: center;
}

.m-prowi {
    width: 90%;
    height: 350px;
    margin: 20px auto;
    background-image: linear-gradient(to bottom, #E0E5F3, #E9EDF3);
}
</style>