<template>
    <div class="application">
        <div class="applititimg">
            <div class="appimgkezi">
                <img src="../../assets/application/applicationbg.webp" alt="bg">
            </div>
        </div>
        <div class="appliconf">
            <h1>{{ langconf('行业领域') }}</h1>
            <div class="tmtdomain">
                <div class="YAG">
                    <img src="../../assets/application/semiconductor.webp" alt="半导体" class="YAGimg">
                    <div>
                        <span>{{ langconf('半导体') }}</span>
                        <p>{{ langconf('半导体质量在线检测') }}</p>
                    </div>
                </div>
                <div class="SHOW">
                    <img src="../../assets/application/display.webp" alt="显示面板" class="SHOWimg">
                    <div>
                        <span>{{ langconf('显示面板') }}</span>
                        <p>{{ langconf('显示面板缺陷在线监测') }}</p>
                    </div>
                </div>
                <div class="GUANG">
                    <img src="../../assets/application/photovoltaic.webp" alt="光伏" class="GUANGimg">
                    <div>
                        <span>{{ langconf('光伏') }}</span>
                        <p>{{ langconf('光伏面板缺陷在线监测') }}</p>
                    </div>
                </div>
                <div class="medicine">
                    <img src="../../assets/application/medicine.webp" alt="医学" class="medicineimg">
                    <div>
                        <span>{{ langconf('医学') }}</span>
                        <p>{{ langconf('细胞检测') }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="appliconf">
            <h1>{{ langconf('最近更新') }}</h1>
            <div class="nearupdate">
                <div>
                    <img src="../../assets/application/Adhesive_inspection.webp" alt="GI">
                    <div>HIO GI-V1 {{ langconf('点胶在线监测') }}</div>
                </div>
                <div>
                    <img src="../../assets/application/6inch_wafer.webp" alt="WI">
                    <div>HIO WI-V1 {{ langconf('有图晶圆表面缺陷快速检测') }}</div>
                </div>
                <div>
                    <img src="../../assets/application/MLA.webp" alt="">
                    <div>HIO WLI-V1 {{ langconf('微透镜阵列检测') }}</div>
                </div>
                <div>
                    <img src="../../assets/application/Cell_monitoring.webp" alt="">
                    <div>HIO VWI-V1 {{ langconf('血细胞三维形貌测量') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
export default {
    setup() {
        const store = useStore();
        const messages = computed(() => store.state.messages);
        const currentLanguage = computed(() => store.state.language);
        const langconf = (key) => {
            return messages.value[currentLanguage.value][key];
        };
        return {
            langconf,
        };
    },
};


</script>
<style scoped>
.medicine img,
.GUANG img,
.SHOW img,
.YAG img {
position: absolute;
  min-width: 100%;
  min-height: 90%;
  width: auto;
  height: auto;
  max-width: none; 
  max-height: none; 
}

.medicine,
.GUANG,
.SHOW,
.YAG {
    height: 300px;
    margin: 0px auto;
    position: relative;
    overflow: hidden;
    justify-content: center;
}

.medicine div,
.GUANG div,
.SHOW div,
.YAG div {
    text-align: left;
    position: relative;
    left: 30px;
    bottom: 100px;
    z-index: 9;
    color: #ffffff;
}

.medicine div span,
.GUANG div span,
.SHOW div span,
.YAG div span {
    font-size: 24px;
}

.medicine div p,
.GUANG div p,
.SHOW div p,
.YAG div p {
    font-size: 12px;
}

.tmtdomain img {
    filter: brightness(0.7);
    position: relative;
    z-index: 2;
    height: 250px;

}

.tmtdomain {
    margin: 0 auto;
    text-align: center;
    width: 90%;
}

.nearupdate div{
    width: 90%;
    overflow: hidden;
    margin: 10px auto;
    height: 250px;
    background-color: #E9EDF3;
}

.nearupdate div div {
    width: 100%;
    position: relative;
    height: 70px;
}

.nearupdate img {
    height: 200px;
}

.appliconf {
    text-align: center;
    margin: 50px auto;
    width: 90%;
}

.appliconf h1 {
    font-size: 26px;
}

.appliconf img {
    width: 100%;
    object-fit: contain;
    object-fit: cover;
}

.applititimg {
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background-image: linear-gradient(to bottom, #a2baff, #E9EDF3);
}

.applititimg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to bottom, rgb(0, 0, 0), rgba(64, 12, 12, 0));
  opacity: 0.5;
  pointer-events: none; /* 防止遮挡内容的交互 */
}

.appimgkezi {
    height: 250px;
    margin: 0 auto;
    align-items: center;
    display: flex;
    width: 100%;
    overflow: hidden;
}

.appimgkezi img {
    margin-bottom: 40px;
    min-width: 120%;
    min-height: 150%;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-fit: cover;
}
</style>
