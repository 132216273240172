<template>
    <div>
        <div class="m-footdiv">
            <div class="m-footbox">
                <div style="height: 40px;"></div>
                <div class="m-footdeal">
                    <div style="display: flex; align-items: center;">
                        <div @click="clickmenu('deal')" class="m-footdealtit">
                            {{ langconf('解决方案') }}
                        </div>
                        <div class="m-heng"></div>
                        <div class="m-shu" :class="{ show: dealisop }"></div>
                    </div>
                    <div class="m-deallist" :class="{ show: dealisop }">
                        <div>
                            <RouterLink to="/product-WI">
                                <div>{{ langconf('WI') }}</div>
                            </RouterLink>
                            <RouterLink to="/product-VWI">
                                <div>{{ langconf('波前测量') }}</div>
                            </RouterLink>
                            <RouterLink to="/product-GI">
                                <div>{{ langconf('GI') }}</div>
                            </RouterLink>
                        </div>
                    </div>
                </div>
                <div class="m-footdeal">
                    <div style="display: flex; align-items: center;">
                        <div @click="clickmenu('app')" class="m-footdealtit">
                            {{ langconf('应用案例') }}
                        </div>
                        <div class="m-heng"></div>
                        <div class="m-shu" :class="{ show: appisop }"></div>
                    </div>
                    <div class="m-applist" :class="{ show: appisop }">
                        <div>
                            <div>{{ langconf('半导体') }}</div>
                            <div>{{ langconf('屏幕与显示') }}</div>
                            <div>{{ langconf('光伏') }}</div>
                            <div>{{ langconf('工业加工') }}</div>
                            <div>{{ langconf('生物医学') }}</div>
                        </div>
                    </div>
                </div>
                <div class="m-footdeal">
                    <div style="display: flex; align-items: center;">
                        <div @click="clickmenu('about')" class="m-footdealtit">
                            {{ langconf('关于我们') }}
                        </div>
                        <div class="m-heng"></div>
                        <div class="m-shu" :class="{ show: aboutisop }"></div>
                    </div>
                    <div class="m-aboutlist" :class="{ show: aboutisop }">
                        <div>
                            <RouterLink to="/contactus">
                                <div>{{ langconf('公司简介') }}</div>
                            </RouterLink>
                        </div>
                    </div>
                </div>
                <div class="m-footdeal">
                    <div style="display: flex; align-items: center;">
                        <div @click="clickmenu('contact')" class="m-footdealtit">
                            {{ langconf('联系我们') }}
                        </div>
                        <div class="m-heng"></div>
                        <div class="m-shu" :class="{ show: contactisop }"></div>
                    </div>
                    <div class="m-contactlist" :class="{ show: contactisop }">
                        <div>
                            <div><a href=mailto:service@hio.technology class="mail">E-Mail: service@hio.technology</a></div>
                            <div><a href="https://ditu.amap.com/place/B0G257WTA3" target="_blank">地址:
                                    浙江省湖州市西塞山路819号</a> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 90%; margin: 0 auto; text-align: center;">
            <div class="m-foottext">
                Copyright © 2022-2023 HIO 上欧科技 版权所有
            </div>
            <div>
                <a href="https://beian.miit.gov.cn/" target="_blank" class="m-foottext">备案号：浙ICP备2023005319号</a>
            </div>
        </div>
    </div>
</template>
<script>
import { ref, onBeforeUnmount, onMounted} from 'vue';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {


    setup() {
        const dealisop = ref(false);
        const appisop = ref(false);
        const aboutisop = ref(false);
        const contactisop = ref(false);
        const store = useStore();
        const messages = computed(() => store.state.messages);
        const currentLanguage = computed(() => store.state.language);
        const langconf = (key) => {
            return messages.value[currentLanguage.value][key];
        };



        const clickmenu = (key) => {
            if (key === 'deal') {
                if (dealisop.value) {
                    dealisop.value = false;
                } else {
                    dealisop.value = true;
                    appisop.value = false;
                    aboutisop.value = false;
                    contactisop.value = false;
                }
            } else if (key === 'app') {
                if (appisop.value) {
                    appisop.value = false;
                } else {
                    dealisop.value = false;
                    appisop.value = true;
                    aboutisop.value = false;
                    contactisop.value = false;
                }
            } else if (key === 'about') {
                if (aboutisop.value) {
                    aboutisop.value = false;
                } else {
                    dealisop.value = false;
                    appisop.value = false;
                    aboutisop.value = true;
                    contactisop.value = false;
                }
            } else if (key === 'contact') {
                if (contactisop.value) {
                    contactisop.value = false;
                } else {
                    dealisop.value = false;
                    appisop.value = false;
                    aboutisop.value = false;
                    contactisop.value = true;
                }
            }
        };
        const beforeRouteChangeHandler = () => {
            // 隐藏内容或重置状态
            dealisop.value = false;
            appisop.value = false;
            aboutisop.value = false;
        };
        // 监听路由切换后事件
        const afterRouteChangeHandler = () => {
            // 在这里可以执行其他操作，如果需要
        };

        // 注册事件监听
        onMounted(() => {
            document.addEventListener('beforeRouteChange', beforeRouteChangeHandler);
            document.addEventListener('afterRouteChange', afterRouteChangeHandler);
        });

        // 在组件销毁时取消事件监听
        onBeforeUnmount(() => {
            document.removeEventListener('beforeRouteChange', beforeRouteChangeHandler);
            document.removeEventListener('afterRouteChange', afterRouteChangeHandler);
        });


        return {
            dealisop,
            appisop,
            aboutisop,
            contactisop,
            clickmenu,
            langconf,
        }
    },
    watch: {
        '$route': function () {
            this.aboutisop = false
            this.appisop = false
            this.dealisop = false
            this.contactisop = false
        }
    },

}

</script>
<style scoped>
.m-shu.show {
    transition: 0.5s;
    height: 0;
}

.m-heng {
    position: absolute;
    float: right;
    background-color: #000000;
    width: 11px;
    height: 1px;
    right: 10px;
}

.m-shu {
    position: absolute;
    float: right;
    background-color: #000000;
    width: 1px;
    height: 11px;
    right: 15px;
    transition: 0.5s;
}


.m-footdealtit {
    border-bottom: #6e6e6e38 1px solid;
    border-top: #6e6e6e38 1px solid;
    position: relative;
    font-size: 14px;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #FBFFE3;
    margin-top: -1px;
}

.m-footbox div div div div {
    position: relative;
    display: flex;
    align-items: center;
    left: 10px;
    height: 50px;
    width: 90%;
    color: #6e6e6e;
    font-size: 13px;
}

.m-aboutlist {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    position: relative;
}

.m-aboutlist.show {
    max-height: 50px;
    transition: max-height 0.3s ease-in-out;
}

.m-contactlist {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    position: relative;
}

.m-contactlist.show {
    max-height: 100px;
    transition: max-height 0.3s ease-in-out;
}

.m-applist {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    position: relative;
}

.m-applist.show {
    max-height: 250px;
    transition: max-height 0.5s ease-in-out;
}

.m-deallist {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    position: relative;
}

.m-deallist.show {
    max-height: 150px;
    transition: max-height 0.5s ease-in-out;
}

.m-foottext {
    position: relative;
    bottom: 20px;
    font-size: 12px;
    margin-bottom: 5px;
}

.m-footdeal {
    position: relative;
    margin-top: 0px;
    align-items: center;
    width: 100%;
}

.m-footdiv {
    width: 100%;
    height: auto;
    background-image: linear-gradient(to right, #FBFFE3, #E6FFEF, #C9F0FE);
}

.m-footbox {
    width: 90%;
    margin: 40px auto;
    bottom: 20px;
}
</style>