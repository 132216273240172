<template>
  <div id="wrap" @mouseover="stopAutoplay" @mouseout="startAutoplay">
    <div class="img-list" :style="{ transform: `translateX(${translateX}%)`, transition: tranitme }">
      <div class="img-wrapper">

        <img src="../../assets/home/lunbo(1).webp" alt="" :style="{ width: `${windowWidth}px` }" />
      </div>
      <div class="img-wrapper">
        <div class="xuanfu" :class="{ show: xuanfu[2] }">
          <div></div>
          <div class="xuanfuch">
            <div class="xuanfutit">{{ langconf('光学精密测量服务') }}</div>
            <div class="learnabout">{{ langconf('了解详情') }} ></div>
          </div>
        </div>
        <img src="../../assets/home/lunbo(2).webp" alt="" :style="{ width: `${windowWidth}px` }" />
      </div>
      <div class="img-wrapper">
        <div class="xuanfu" :class="{ show: xuanfu[3] }">
          <div class="xuanfuch">
            <div class="xuanfutit">{{ langconf('针对半导体行业的在线质量检测') }}</div>
            <div class="learnabout">{{ langconf('了解详情') }} ></div>
          </div>
        </div>
        <img src="../../assets/home/lunbo(3).webp" alt="" :style="{ width: `${windowWidth}px` }" />
      </div>
      <div class="img-wrapper">
        <div class="xuanfu" :class="{ show: xuanfu[4] }">
          <div class="xuanfuch">
            <div class="xuanfutit">{{ langconf('光学精密测量服务') }}</div>
            <div class="learnabout">{{ langconf('了解详情') }} ></div>
          </div>
        </div>
        <img src="../../assets/home/lunbo(4).webp" alt="" :style="{ width: `${windowWidth}px` }" />
      </div>
      <div class="img-wrapper">
        <div class="xuanfu" :class="{ show: xuanfu[5] }">
          <div class="xuanfuch">
            <div class="xuanfutit">{{ langconf('光学设计服务') }}</div>
            <div class="learnabout">{{ langconf('了解详情') }} ></div>
          </div>
        </div>
        <img src="../../assets/home/lunbo(5).webp" alt="" :style="{ width: `${windowWidth}px` }" />
      </div>
      <div class="img-wrapper">
        <img src="../../assets/home/lunbo(1).webp" alt="" :style="{ width: `${windowWidth}px` }" />
      </div>
    </div>

    <div class="arrow" :class="{ show: arrowsVisible }">
      <span class="left" @click="prevImage"><img src="../../assets/widget/left_arrow_white.webp" class="left-arrow"></span>
      <span class="right" @click="nextImage"><img src="../../assets/widget/right_arrow_white.webp"
          class="right-arrow"></span>
    </div>

    <ul class="circle-list">
      <li class="circle" v-for="(circle, index) in circles" :key="index" :class="{ active: index === circleIndex }"
        :data-n="index" @click="selectImage(index)"></li>
    </ul>
  </div>
</template>
    
<script>
import { useStore } from 'vuex';
import { computed } from 'vue';


export default {
  setup() {
    const store = useStore();
    const messages = computed(() => store.state.messages);
    const currentLanguage = computed(() => store.state.language);
    const langconf = (key) => {
      return messages.value[currentLanguage.value][key];
    };
    return {
      langconf,
    }

  },
  data() {
    return {
      currentIndex: 0,
      circleIndex: 0,
      circles: [],
      translateX: 0,
      autoplay: null,
      windowWidth: 0,
      arrowsVisible: false,
      timer: null,
      tranitme: 0,
      lock: false,
      xuanfu: {
        '1': false,
        '2': false,
        '3': false,
        '4': false,
        '5': false,
      },
    };
  },

  created() {
    this.circles = new Array(5).fill("");
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    window.addEventListener("resize", this.handleWindowResize);
    this.startAutoplay();
    this.updatexuanfu();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
    this.stopAutoplay();
  },
  methods: {
    handleWindowResize() {
      this.windowWidth = window.innerWidth;
    },
    prevImage() {
      if (this.lock) return;
      this.tranitme = '0.5s ease';
      this.currentIndex--;
      this.circleIndex = this.currentIndex;
      this.updatexuanfu();
      if (this.currentIndex === -1) {
        this.tranitme = 'none';
        this.currentIndex = 5;
        setTimeout(() => {
          this.tranitme = '0.5s ease';
          this.currentIndex = 4;
          this.circleIndex = this.currentIndex;
          this.updateTranslateX();
        }, 0);
      }
      this.updateTranslateX();
      this.lock = true;
      setTimeout(() => {
        this.lock = false;
      }, 500);
    },
    nextImage() {
      if (this.lock) return;
      this.tranitme = '0.5s ease';
      this.currentIndex++;
      this.circleIndex = this.currentIndex;
      this.updatexuanfu();
      if (this.currentIndex === 5) {
        this.circleIndex = 0;
        setTimeout(() => {
          this.currentIndex = 0;
          this.tranitme = 'none';
          this.updateTranslateX();
        }, 500);
      }
      this.updateTranslateX();
      this.lock = true;
      setTimeout(() => {
        this.lock = false;
      }, 500);
    },
    selectImage(index) {
      this.tranitme = '0.5s ease';
      this.currentIndex = index;
      this.circleIndex = index;
      this.updateTranslateX();
      this.updatexuanfu();
      console.log(this.xuanfu);
    },
    updateTranslateX() {
      this.translateX = -this.currentIndex * 100;
    },

    updatexuanfu() {
      setTimeout(() => {
        this.xuanfu[this.currentIndex + 1] = true;
        Object.entries(this.xuanfu).map(([key]) => {
          if (key !== String(this.currentIndex + 1)) {
            this.xuanfu[key] = false;
          }
        })
        if (this.currentIndex === 5) {
          this.xuanfu[1] = true;
        }
        if (this.currentIndex === -1) {
          this.xuanfu[5] = true;
        }
      }, 500)
    },
    startAutoplay() {
      this.arrowsVisible = false;
      this.timer = setInterval(() => {
        this.nextImage();
      }, 4000);
    },
    stopAutoplay() {
      this.arrowsVisible = true;
      clearInterval(this.timer);
    },
  }
};
</script>
    
<style scoped>
.xuanfutit {
  position: absolute;
  margin-top: 20px;
  margin-left: 20px;
  font-size: 24px;
  width: 350px;
  /* background-color: #3A8CFC; */
}

.xuanfuch {
  background-color: rgba(255, 255, 255);
  height: 200px;
  width: 400px;
  margin-left: 200px;
  color: #000;
}

.learnabout {
  position: relative;
  top: 150px;
  margin-left: 20px;
  font-size: 16px;
  color: #3A8CFC;
  font-weight: 550;
}

.xuanfu {
  position: relative;
  height: 0px;
  left: 20px;
  top: 200PX;
  font-weight: lighter;
  z-index: 999;
  cursor: pointer;
  opacity: 0;

  letter-spacing: 1px;
}

.xuanfu.show {
  transform: translateY(200px);
  opacity: 0.9;
  transition: 0.5s;
}


#wrap {
  position: relative;
  overflow: hidden;
  margin: -80px auto;
  width: 100%;
  height: 800px;
}

.img-list {
  display: inline-flex;
  transition: none;
}

.img-wrapper {
  flex-shrink: 0;
  width: 100%;
  height: 800px;
  overflow: hidden;
}

.img-wrapper img {
  height: 100%;
  object-fit: cover;
  transition: 0.3s ease;
  /* display: block; */
}

#wrap:hover .img-wrapper img {
  transform: scale(1.05);
}

#wrap .img-list {
  display: flex;
  position: relative;
  left: 0px;
  width: 100%;
  height: 100%;
}

#wrap .img-list img {
  height: 100%;
}

#wrap span {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  display: block;
  width: 48px;
  height: 96px;
  user-select: none;
  text-align: center;
  line-height: 70px;
  cursor: pointer;
}

#wrap span.left {
  left: 0;
  transition: 0.3s ease;
}

#wrap span.left:hover {
  background-color: rgba(0, 0, 0, 0.449);
  left: 0;
  border-radius: 0px 8px 8px 0px;
  transition: 0.3s ease;
}


#wrap span.right {
  right: 0;
  transition: 0.3s ease;
}

#wrap span.right:hover {
  background-color: rgba(0, 0, 0, 0.449);
  right: 0;
  border-radius: 8px 0px 0px 8px;
  transition: 0.3s ease;
}


.circle-list {
  display: flex;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 300px;
  height: 5px;
  z-index: 8;
}

.circle-list>.circle {
  position: relative;
  margin: 0 5px;
  width: 60px;
  height: 5px;
  cursor: pointer;
  background-color: #ffffff32;
}

#wrap:hover .circle-list>.circle.active:before {
  animation: none;
}

#wrap:hover .circle-list>.circle.active {
  background-color: #ffffff;
}

@keyframes banner-active {
  from {
    width: 0px;
  }

  to {
    width: 100%;
  }
}


.circle-list>.circle.active:before {
  content: "";
  position: absolute;
  background-color: #ffffff;
  left: 0;
  height: inherit;
  width: 0px;
  animation: banner-active 4s linear infinite;
}

ul {
  list-style: none;
}

.arrow {
  opacity: 0;
  transition: opacity 0.3s;
}

.arrow.show {
  opacity: 1;
}

.right-arrow,
.left-arrow {
  position: absolute;
  width: 48px;
  top: 50%;
  transform: translate(-50%, -50%);
}</style>
    