<template>
    <div>
        <div class="pa">
            <h1 class="producttitle">{{ langconf('产品解决方案') }}</h1>
            <div class="product_div">
                <div class="left_div" @click="chosepro('wi')">
                    <div class="hiotit">
                        <h1>{{ langconf('WI') }}</h1>
                        <h3>HIO WI-V1</h3>
                    </div>
                    <img class="home_wi_img" src="../../assets/wi/WI.webp" alt="在线高分辨率检测仪">
                </div>
                <div class="rightproductdiv">
                    <div class="other_div" @click="chosepro('gi')">
                        <div class="hiotit">
                            <h1>{{ langconf('GI') }}</h1>
                            <h3>HIO GI-V1</h3>
                        </div>
                        <img class="other_img" src="../../assets/gi/GI.webp" alt="点胶AOI">
                    </div>
                    <div class="other_div" @click="chosepro('wli')">
                        <div class="hiotit">
                            <h1>{{ langconf('WLI') }}</h1>
                            <h3>HIO WLI-V1</h3>
                        </div>
                        <img class="other_img" src="../../assets/wli/WLI.webp" alt="晶圆透镜波前测量仪">
                    </div>
                    <div class="other_div" @click="chosepro('vwi')">
                        <div class="hiotit">
                            <h1>{{ langconf('VWI') }}</h1>
                            <h3>HIO VWI-V1</h3>
                        </div>
                        <img class="other_img" src="../../assets/vwi/VWI.webp" alt="台式波前测量仪">
                    </div>
                    <div class="other_div" @click="chosepro('iol')">
                        <div class="hiotit">
                            <h1>{{ langconf('IOL') }}</h1>
                            <h3>HIO IOL-V1</h3>
                        </div>
                        <img class="other_img" src="../../assets/iol/IOL.webp" alt="人工晶体波前测量仪">
                    </div>
                </div>
            </div>
            <div class="friendsandus">
                <div class="friends_div">
                    <h1>{{ langconf('合作伙伴') }}</h1>
                    <div></div>
                    <img src="../../assets/home/partnership.webp" alt="">
                </div>
                <div class="constus">
                    <h1>{{ langconf('关于我们') }}</h1>
                    <div></div>
                    <p>{{ langconf('关于我们内容') }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import router from '../../tools/router';
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
    setup() {
        const store = useStore();
        const messages = computed(() => store.state.messages);
        const currentLanguage = computed(() => store.state.language);
        const langconf = (key) => {
            return messages.value[currentLanguage.value][key];
        };
        return{
            langconf
        }
    },
    data() {
        return {

        }
    },
    methods: {
        chosepro(pro) {
            if (pro == 'wi') {
                router.push({
                    path: '/product-WI',
                })
            }
            if (pro == 'gi') {
                router.push({
                    path: '/product-GI',
                })
            }
            if (pro == 'wli') {
                router.push({
                    path: '/product-WLI'
                })
            }
            if (pro == 'vwi') {
                router.push({
                    path: '/product-VWI'
                })
            }
            if (pro == 'iol') {
                router.push({
                    path: '/product-IOL'
                })
            }
        }
    }
}

</script>
<style scoped>
.friendsandus div img {
    width: 95%;
    float: left;
}

.friendsandus div div {
    width: 50%;
    height: 5px;
    margin: 20px auto 20px 0;
    background-color: #6281de;
    border-radius: 2px;
}

.constus p {
    font-size: 18px;
    line-height: 27px;
    font-weight: 200;
    text-align: left;
}

.constus h1 {
    margin: 0;
    padding: 0;
    text-align: left;
    font-size: 28px;
}

.constus {
    width: 45%;
    height: 300px;
    margin: 0 auto auto 20px;
}

.friends_div h1 {
    margin: 0;
    padding: 0;
    text-align: left;
    font-size: 28px;
}

.friends_div {
    height: 300px;
    width: 45%;
    margin: 0 auto auto 30px;
}

.friendsandus {
    width: 1480px;
    height: 300px;
    display: flex;
    margin: 100px auto;
    display: flex;
    text-align: center;
    justify-content: center;
}

.pa {
    text-align: center;
    margin: 0 auto;
    width: 1480px;
}

.hiotit {
    margin: 20px 0 20px 0;
}

.hiotit h1 {
    font-size: 20px;
    margin: 0;
    padding: 0;
}

.hiotit h3 {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 200;
}

.producttitle {
    text-align: center;
    margin: 80px 0 60px 0;
    font-size: 26px;
}

.home_wi_img {
    width: 100%;
    margin-top: 30px;
    transition: all 0.4s;
}

.product_div {
    width: 1480px;
    height: 600px;
    display: flex;
    text-align: center;
    justify-content: center;
}

.left_div {
    width: 48%;
    height: 100%;
    overflow: hidden;
    /* background-image: linear-gradient(to bottom, #E0E5F3, #E9EDF3); */
    background-color: #ECECEE;
    cursor: pointer;
}

.left_div:hover .home_wi_img {
    transform: scale(1.1);
}

.other_div {
    position: relative;
    width: 47%;
    height: 49.2%;
    margin: 0px 10px 10px 0;
    overflow: hidden;
    /* background-image: linear-gradient(to bottom, #E0E5F3, #E9EDF3); */
    background-color: #ECECEE;
    cursor: pointer;
}

.other_img {
    width: 100%;
    transition: all 0.4s;
    position: absolute;
    top: 35%;
    left: 5%;
}

.other_div:hover .other_img {
    transform: scale(1.1);
}

.rightproductdiv {
    position: relative;
    width: 48%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
</style>










