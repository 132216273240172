<template>
    <slidesComp/>
    <div style="height: 80px;"></div>
    <homeComp/>
</template>
<script>
// import { ref, onMounted, onBeforeUnmount } from 'vue';
// import router from '@/tools/router';
import slidesComp from '@/components/pc/slidesComp.vue';
import homeComp from '@/components/pc/homeComp.vue';


export default {
    components: {
        slidesComp,
        homeComp,
    },
//   setup() {
//     const windowWidth = ref(window.innerWidth);
//     const handleResize = () => {
//       windowWidth.value = window.innerWidth;
//     if (windowWidth.value>450) {
//         router.push('/m/home')
//     }
//     };

//     onMounted(() => {
//       window.addEventListener('resize', handleResize);
//     });

//     onBeforeUnmount(() => {
//       window.removeEventListener('resize', handleResize);
//     });
//     return {
//       windowWidth
//     };
//   }
};
</script>
