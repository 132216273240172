<template>
    <div class="recruit">
        <div class="recruitbg">
            <div class="recruittext">
                <div>{{ langconf('招聘题目') }}</div>
                <p>{{ langconf('招聘理念') }}</p>
            </div>
            <img src="../../assets/recruitment/RecruitmentBg.webp" alt="招聘图">
        </div>
        <div>
            <div class="recruittit">
                <div class="recruitconf">
                    <h1>{{ langconf('找到团队') }}</h1>
                </div>
                <div class="teamconf">
                    <div class="teammenu">
                        <div class="rdteam">
                            <div class="teamname">
                                {{ langconf('研发团队') }}
                            </div>
                            <a href="" class="hreftozhi1">{{ langconf('在招职位') }} ></a>
                        </div>
                        <div class="fcteam">
                            <div class="teamname">
                                {{ langconf('职能团队') }}
                            </div>
                            <a href="" class="hreftozhi2">{{ langconf('在招职位') }} ></a>
                        </div>
                        <div class="saleteam">
                            <div class="teamname">
                                {{ langconf('市场销售团队') }}
                            </div>
                            <a href="" class="hreftozhi3">{{ langconf('在招职位') }} ></a>
                        </div>
                        <div class="desteam">
                            <div class="teamname">
                                {{ langconf('设计团队') }}
                            </div>
                            <a href="" class="hreftozhi4">{{ langconf('在招职位') }} ></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
export default {
    setup() {
        const store = useStore();
        const messages = computed(() => store.state.messages);
        const currentLanguage = computed(() => store.state.language);
        const langconf = (key) => {
            return messages.value[currentLanguage.value][key];
        };
        return {
            langconf,
        };
    },
};


</script>
<style scoped>

.hreftozhi1,
.hreftozhi2,
.hreftozhi3,
.hreftozhi4 {
    position: relative;
    margin-left: 20px;
    font-size: 14px;
    color: #3A8CFC;
    top: 110px;
}

.teamname {
    margin-top: 20px;
    margin-left: 20px;
    font-size: 20px;
}

.teamconf {
    width: 1280px;
    margin: 0 auto;
    margin-bottom: 80px;
}

.desteam,
.rdteam,
.fcteam,
.saleteam {
    height: 200px;
    width: 200px;
    background-color: #F1F1F1;
    margin: 5px;
    border-radius: 10px;
}

.teammenu {
    display: flex;
    width: 840px;
    margin: 0 auto;
}

.recruit {
    margin: -80px auto 0 auto;
}

.recruittit {
    width: 1280px;
    margin: 0 auto;
}

.recruitbg {
    position: r;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.recruitbg img {
    width: 2560px;
    height: 700px;
    object-fit: cover;
    filter: brightness(0.5);
}

.recruittext {
    top: 20%;
    position: absolute;
    z-index: 1;
    width: 1280px;
    margin: 0 auto;
    color: #ffffff;
}

.recruittext div {
    font-size: 40px;
    width: 1280px;
    text-align: center;

}

.recruittext p {
    width: 1280px;
    text-align: center;

}

.recruitconf {
    text-align: center;
}
</style>