<template>
    <div class="footerdiv">
        <div class="footermenu">
            <ul class="footerlist">
                <li class="footertit">{{ langconf('解决方案') }}</li>
                <li><a @click="chose('wi')">{{ langconf('WI') }}</a></li>
                <li><a @click="chose('vwi')">{{ langconf('VWI') }}</a></li>
                <li><a @click="chose('gi')">{{ langconf('GI') }}</a></li>
            </ul>
            <ul class="footerlist">
                <li class="footertit"><a @click="chose('app')">{{ langconf('应用领域') }}</a></li>
                <li>
                    <div href="">{{ langconf('半导体') }}</div>
                </li>
                <li>
                    <div href="">{{ langconf('屏幕与显示') }}</div>
                </li>
                <li>
                    <div href="">{{ langconf('光伏') }}</div>
                </li>
                <li>
                    <div href="">{{ langconf('工业加工') }}</div>
                </li>
                <li>
                    <div href="">{{ langconf('生物医学') }}</div>
                </li>
            </ul>
            <ul class="footerlist">
                <li class="footertit">{{ langconf('关于我们') }}</li>
                <li><a @click="chose('company')">{{ langconf('公司简介') }}</a></li>
            </ul>
            <ul class="footerlist">
                <li class="footertit">{{ langconf('联系我们') }}</li>
                <li><a href=mailto:service@hio.technology class="mail">E-Mail: service@hio.technology</a></li>
                <li><a href="https://ditu.amap.com/place/B0G257WTA3" target="_blank">地址:
                        浙江省湖州市西塞山路819号</a></li>
                <!-- <li class="QR"><img src="../assets/compimg/QR.webp" class="QR"></li> -->
            </ul>
        </div>
        <div class="beianconf">
            <span>Copyright © 2022-2023 HIO {{ langconf('版权') }}</span>
            <span><a href="https://beian.miit.gov.cn/" target="_blank">备案号: 浙ICP备2023005319号</a></span>
        </div>
    </div>
</template>
<script>
import router from '../../tools/router';
import { useStore } from 'vuex';
import { computed } from 'vue';


export default {
    setup() {
        const store = useStore();
        const messages = computed(() => store.state.messages);
        const currentLanguage = computed(() => store.state.language);
        const langconf = (key) => {
            return messages.value[currentLanguage.value][key];
        };

        const chose = (key) => {
            if (key == 'wi') {
                router.push('/product-WI')
            }
            if (key == 'vwi') {
                router.push('/product-VWI')
            }
            if (key == 'gi') {
                router.push('/product-GI')
            }
            if (key == 'app') {
                router.push('/application')
            }
            if (key == 'company') {
                router.push('/contactus')
            }
        }
        return {
            chose,
            langconf,
        }
    }
}
</script>
<style scoped>
li {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.footerdiv {
    width: 100%;
    background-color: rgb(50, 50, 50);
    position: relative;
    clear: #ffffff;
}

.footermenu {
    margin: 0 auto;
    width: 1480px;
    display: flex;
    justify-content: center;
}

.footertit {
    font-weight: 600;
    margin-bottom: 50px;

}

.footermenu ul {
    width: 250px;
    margin: 50px 0;
    padding: 0;
}

.footermenu ul li {
    margin-bottom: 15px;
}

.footermenu ul li a {
    color: #000000;
    cursor: pointer;
}

.footermenu ul li:nth-child(1) {
    margin-bottom: 32px;
}

.footerdiv {
    width: 100%;
}

.QR {
    width: 100px;
}

.beianconf {
    text-align: center;
    height: 65px;
    line-height: 60px;
}

.beianconf span {
    margin: 20px;
    font-size: 14px;
    font-weight: 100;
    color: #6b6b6b;
}

.beianconf span a {
    color: #6b6b6b;
}

.footerdiv a {
    text-decoration: none;
}

.footermenu ul li a {
    position: relative;
    z-index: 1;
    overflow: hidden;
    color: #ffffff;
}

.footermenu ul li {
    color: #ffffff;
}


.footermenu ul li a:before {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #ffffff;
    transition: width 0.3s ease;
}

.footermenu ul li a:hover::before {
    width: 100%;
}
</style>