<template>
  <div style="width: 100%; height: 40px; position: fixed; z-index: 999;" class="m-menubox" :class="{ show: togglebg }">
    <div style="position: absolute;">
      <div class="button" @click="toggleMenu()" :class="{ show: togglebg }">
        <div class="button1" :class="{ 'buttonClose': buttonClose, show: togglebg }"></div>
        <div class="button2" :class="{ 'buttonClose': buttonClose, show: togglebg }"></div>
        <div class="button3" :class="{ 'buttonClose': buttonClose, show: togglebg }"></div>
      </div>
    </div>
    <div>
      <div class="menu" :class="{ 'show': buttonClose }">
        <div class="menulist">
          <div class="promenu">
            <div class="promenugroup">
              <div class="promenudiv" @click="menuisop = !menuisop">
                {{ langconf('产品技术') }}
                <img src="../../assets/widget/down_arrow_black.webp" :class="{ show: menuisop }">
              </div>
              <div class="prolist" :class="{ show: menuisop }">
                <div @click="to('wi')">{{ langconf('WI') }}<img src="../../assets/widget/right_arrow_black.webp" class="right-arrow">
                </div>
                <div @click="to('gi')">{{ langconf('GI') }}<img src="../../assets/widget/right_arrow_black.webp" class="right-arrow">
                </div>
                <div @click="to('wli')">{{ langconf('WLI') }}<img src="../../assets/widget/right_arrow_black.webp" class="right-arrow">
                </div>
                <div @click="to('vwi')">{{ langconf('VWI') }}<img src="../../assets/widget/right_arrow_black.webp" class="right-arrow">
                </div>
                <div @click="to('iol')">{{ langconf('IOL') }}<img src="../../assets/widget/right_arrow_black.webp" class="right-arrow">
                </div>
              </div>
            </div>
          </div>
          <div class="othermenu" @click="to('app')"><a>{{ langconf('应用案例') }}<img src="../../assets/widget/right_arrow_black.webp"
                class="right-arrow"></a></div>
          <div class="othermenu" @click="to('news')"><a>{{ langconf('新闻中心') }}<img src="../../assets/widget/right_arrow_black.webp"
                class="right-arrow"></a></div>
          <div class="othermenu" @click="to('esg')"><a>ESG<img src="../../assets/widget/right_arrow_black.webp"
                class="right-arrow"></a></div>
          <div class="othermenu" @click="to('contus')"><a>{{ langconf('联系我们') }}<img src="../../assets/widget/right_arrow_black.webp"
                class="right-arrow"></a></div>
          <div class="othermenu" @click="to('recruit')"><a>{{ langconf('招贤纳士') }}<img src="../../assets/widget/right_arrow_black.webp"
                class="right-arrow"></a></div>

        </div>
      </div>
    </div>
    <div class="mobilelogo">
      <a href="/"><img class="logoimg" :class="{ show: togglebg }"></a>
    </div>
    <div class="changelang">
      <div class="langmenu" :class="{ show: togglebg }" @click="togglang = !togglang">
        <div class="showlong" :class="{ show: togglebg }">{{ showlang }}</div>
        <img alt="" class="down-arrow" :class="{ show: togglebg, range: togglang }">
      </div>
      <div class="langlist" :class="{ show: togglang }" @click="togglang = !togglang">
        <div @click="switchLanguage('zh')">简体</div>
        <div @click="switchLanguage('zh')">繁体</div>
        <div @click="switchLanguage('en')">EN</div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { computed } from 'vue';
import router from '@/tools/router';

export default {
  setup() {

    const togglang = ref(false);
    const menuisop = ref(false)
    const xiahua = ref(false)
    const togglebg = ref(false);
    const buttonClose = ref(false);
    const scrollListener = ref(null);
    let hasScrolled = false;


    const showlang = ref('');
    const store = useStore();
    const messages = computed(() => store.state.messages);
    const currentLanguage = computed(() => store.state.language);

    if (currentLanguage.value == 'zh') {
      showlang.value = '简';
    } else if (currentLanguage.value == 'en') {
      showlang.value = 'en';
    }
    const langconf = (key) => {
      return messages.value[currentLanguage.value][key];
    };

    const switchLanguage = (lang) => {
      store.commit('setLanguage', lang);
      localStorage.setItem('language', lang);
      if (lang == 'zh') {
        showlang.value = '简';
      } else if (lang == 'en') {
        showlang.value = 'en';
      }
    };




    const to = (url) => {

      if (url === 'wi') {
        toggleMenu()
        router.push('/product-WI');
      }
      if (url === 'gi') {
        toggleMenu()
        router.push('/product-GI');
      }
      if (url === 'wli') {
        toggleMenu()
        router.push('/product-WLI');
      }
      if (url === 'vwi') {
        toggleMenu()
        router.push('/product-VWI');
      }
      if (url === 'iol') {
        toggleMenu()
        router.push('/product-IOL');
      }
      if (url === 'app') {
        toggleMenu()
        router.push('/application');
      }
      if (url === 'news') {
        toggleMenu()
        router.push('/mnews');
      }
      if (url === 'esg') {
        toggleMenu()
        router.push('/esg');
      }
      if (url === 'contus') {
        toggleMenu()
        router.push('/contactus');
      }
      if (url === 'recruit') {
        toggleMenu()
        router.push('/recruit');
      }
    }


    const toggleMenu = () => {
      buttonClose.value = !buttonClose.value;
      if (!xiahua.value) {
        if (!togglebg.value && buttonClose.value) {
          togglebg.value = true
        }
        if (togglebg.value && !buttonClose.value) {
          togglebg.value = false
        }
      }
      if (buttonClose.value) {
        document.body.style.overflow = 'hidden';
        togglang.value = false
      } else {
        document.body.style.overflow = '';
        menuisop.value = false
      }
    }
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 20 && !hasScrolled) {
        togglebg.value = true
        xiahua.value = true
        hasScrolled = true;
      } else if (scrollY <= 20 && hasScrolled) {
        togglebg.value = false;
        hasScrolled = false;
        xiahua.value = false
      }
    };

    onMounted(() => {
      scrollListener.value = handleScroll;
      window.addEventListener('scroll', scrollListener.value);
    });
    onBeforeUnmount(() => {
      window.removeEventListener('scroll', scrollListener.value);
    });

    return {
      buttonClose,
      toggleMenu,
      togglebg,
      menuisop,
      to,
      togglang,
      showlang,
      langconf,
      switchLanguage,
    };
  },
}
</script>
<style scoped>
.langlist {
  max-height: 0;
  overflow: hidden;
  transition: 0.3s;
  width: calc(100% + 2px);
  background-color: #ffffff;
  border-radius: 2px;
}

.langlist.show {
  max-height: 110px;

}

.langlist div {
  margin-top: 4px;
  font-size: 16px;

  margin: 12px auto;
  text-align: center;
}


.right-arrow {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

.menulist {
  margin-left: 20px;
}


.othermenu {
  position: relative;
  height: 40px;
  width: 90%;
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.promenudiv {
  margin-top: 30px;
  height: 40px;
  width: 90%;
  position: relative;
  display: flex;
  align-items: center;
}

.promenugroup {
  width: 100%;
}

.promenudiv img {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 0;
  transition: 0.5s;
}

.promenugroup img.show {
  transform: rotate(180deg);
}

.prolist {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  position: relative;
  top: 10px;
  width: 85%;
}

.prolist.show {
  max-height: 200px;
  transition: max-height 0.5s ease-in-out;
}

.prolist div {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-bottom: 10px;
  height: 30px;
}

.m-menubox.show {
  background-color: #ffffff;
  transition: 0.3s;
}

.m-menubox {
  transition: 0.3s;
}

.langmenu {
  border: 1px solid #ffffff;
  border-radius: 3px;
  height: 22px;
  margin-top: 8px;
  width: 48px;
  display: flex;
  align-items: center;
}

.langmenu.show {
  border: 1px solid #000000;
}

.showlong {
  color: #ffffff;
  display: flex;
  height: 100%;
  font-size: 16px;
  align-items: center; 
  margin-left: 6px;
  /* background-color: aquamarine; */
}

.showlong.show {
  color: #000000;
}

.down-arrow {
  width: 24px;
  height: 24px;
  content: url('../../assets/widget/down_arrow_wite.webp');
  transition: 0.3s;
}

.down-arrow.show {
  content: url('../../assets/widget/down_arrow_black.webp');
}

.down-arrow.range {
  transform: rotate(180deg);

}

.changelang {
  position: absolute;
  top: 0px;
  right: 20px;
  width: 48px;
  height: 24px;
}

.mobilelogo {
  position: relative;
  height: 24px;
  width: 105px;
  margin: 0 auto;
  margin-top: 9px;
}

.mobilelogo img {
  height: 24px;
  content: url('../../assets/widget/logo_white.webp');
}

.logoimg.show {
  content: url('../../assets/widget/logo_colorful.webp');
}

.menu {
  position: absolute;
  z-index: -1;
  transform: translateY(-972px);
  width: 100%;
  height: 900px;
  top: 40px;
  left: 0;
  transition: 0.5s;
  z-index: 9;
}

.menu.show {
  background-color: #dbdbdb;
  position: absolute;
  transform: translateY(0);
  top: 40px;
  left: 0;
  transition: 0.5s;
  z-index: 999;
}

.button {
  position: relative;
  width: 24px;
  height: 24px;
  z-index: 999;
  left: 20px;
  top: 3px;
  transition: 10s;
}

.button div {
  position: relative;
  background-color: #ffffff;
  width: 24px;
  height: 2px;
  border-radius: 1px;
  margin: 7px 0 7px 0;
  transition: 0.5s, background-color 0s;
}

.button div.show {
  background-color: #000000;
  transition: 0.5s, background-color 0s;
}

.button1.buttonClose {
  margin: 7px auto auto 3.5px;
  transform-origin: left center;
  transform: rotate(45deg);
  transition: 0.5s;
}

.button2.buttonClose {
  opacity: 0;
  transition: 0.5s;
}

.button3.buttonClose {
  margin: -1px auto auto 3.5px;
  transform-origin: left center;
  transform: rotate(-45deg);
  transition: 0.5s;
}
</style>
  