<template>
    <div class="WIhome">
        <div class="wibg">
            <div class="titimg">
                <div class="tittext">
                    <h1>{{ langconf('WLI') }}</h1>
                    <p>HIO WLI-V1</p>
                </div>
                <img src="../../assets/wli/WLI.webp" alt="晶圆透镜波前测量仪">
            </div>
        </div>
        <div class="widetail">
            <p>{{ langconf('WLI介绍') }}</p>
        </div>
        <div class="wi-spic">
            <h1>{{ langconf('技术规格') }}</h1>
            <img src="../../assets/wli/WLI_parameters.webp" alt="WLI规格">
            <img src="" alt="">
        </div>
        <div>
            <video src=""></video>
        </div>
        <div class="appliarea">
            <h1>{{ langconf('应用领域') }}</h1>
            <div class="wliappliarea">
                <div>
                    <img src="../../assets/wli/MLA.webp" alt="">
                    <div>{{ langconf('微透镜测量') }}</div>
                </div>
                <div>
                    <img src="../../assets/wli/MEMS.webp" alt="">
                    <div>{{ langconf('硅基MEMS器件测量') }}</div>
                </div>
            </div>
        </div>
        <div class="resources">
            <h1>{{ langconf('相关资源') }}</h1>
            <div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</template>
<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
export default {
    setup() {
        const store = useStore();
        const messages = computed(() => store.state.messages);
        const currentLanguage = computed(() => store.state.language);
        const langconf = (key) => {
            return messages.value[currentLanguage.value][key];
        };
        return {
            langconf,
        };
    },
};

</script>
<style scoped>
.WIhome {
    margin: -80px auto 0 auto;
}


.wliappliarea {
    display: flex;
    width: 1000px;
    margin: 0 auto;
}

.wliappliarea div {
    width: 300px;
    height: 200px;
    margin: auto;
}

.wliappliarea div div {
    font-size: 24px;
}


.wliappliarea div img {
    width: 300px;
    height: 200px;
}

.wibg {
    background-color: #A3C0EC;
}

.resources {
    text-align: center;
    margin-top: 100px;
}

.appliarea {
    text-align: center;
}

.appliarea h1{
    margin-bottom: 50px;
}

.wi-spic {
    text-align: center;
    width: 800px;
    margin: 0 auto;
}

.wi-spic img {
    width: 800px;
}

.widetail p {
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    color: #000000;
    text-align: left;
}

.widetail {
    width: 980px;
    margin: 100px auto;
}

.titimg {
    position: relative;
    width: 980px;
    height: 500px;
    display: flex;
    align-items: center;
    margin: 0 auto;
}

.tittext {
    position: absolute;
    width: 500px;
    text-align: center;
}

.titimg img {
    position: absolute;
    width: 640px;
    left: 400px;
}
</style>