<template>
    <div class="div404">
        <div class="text404">
            <p class="ab404">
                {{ langconf('页面正在建设') }}
            </p>
        </div>
        <div class="backhome404but">
            <RouterLink to="/home">
                <div class="backhome404" @click="backhome">
                    <div class="backhometxt">{{ langconf('返回首页') }}</div>
                </div>
            </RouterLink>
        </div>
    </div>
</template>
<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
export default {
    setup() {
        const store = useStore();
        const messages = computed(() => store.state.messages);
        const currentLanguage = computed(() => store.state.language);
        const langconf = (key) => {
            return messages.value[currentLanguage.value][key];
        };
        return {
            langconf,
        };
    },
};

</script>
<style scoped>
.ab404 {
    font-size: 40px;
    color: #000000;
    width: 1200px;
    text-align: center;
}


.div404 {
    width: 100%;
    height: 100%;
    margin: 80px auto;

}

.div404::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 480px;
  background: linear-gradient(to bottom, rgb(0, 0, 0), rgba(64, 12, 12, 0));
  opacity: 0.5;
  pointer-events: none; /* 防止遮挡内容的交互 */
}

.backhome404 {
    position: relative;
    width: 200px;
    height: 50px;
    border: 0;
    margin: 0 auto;
    border-radius: 5px;
    background-color: rgb(176, 108, 239);
    cursor: pointer;
}

.backhometxt {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
}

.backhome404but {
    width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.text404 {
    width: 1200px;
    height: 500px;
    background-image: url(../../assets/404/404.webp);
    background-repeat: no-repeat;
    background-size: 100%;
    margin: 0 auto;
    align-items: center;
    display: flex;
}
</style>