<template>
  <div id="wrap" style="width: 100%;" @touchstart="touchStart" @touchmove="touchMove" @touchend="touchEnd"
    @click="emememem">
    <div class="img-list" :style="{ transform: `translateX(${translateX}%)`, transition: tranitme }">
      <div class="img-wrapper">
        <div class="xuanfu" :class="{ show: xuanfu[1] }">
          <div class="xuanfuch">
            <div class="xuanfutit">{{ langconf('了解我们的产品') }}</div>
            <div class="learnabout">{{ langconf('了解详情') }} >
            </div>
          </div>
        </div>
        <img src="../../assets/home/lunbo(1)_m.webp" alt="" :style="{ width: `${windowWidth}px` }" />
      </div>
      <div class="img-wrapper">
        <div class="xuanfu" :class="{ show: xuanfu[2] }">
          <div class="xuanfuch">
            <div class="xuanfutit">{{ langconf('光学精密测量服务') }}</div>
            <div class="learnabout">{{ langconf('了解详情') }} ></div>
          </div>
        </div>
        <img src="../../assets/home/lunbo(2).webp" alt="" :style="{ width: `${windowWidth}px` }" />
      </div>
      <div class="img-wrapper">
        <div class="xuanfu" :class="{ show: xuanfu[3] }">
          <div class="xuanfuch">
            <div class="xuanfutit">{{ langconf('针对半导体行业的在线质量检测') }}</div>
            <div class="learnabout">{{ langconf('了解详情') }} ></div>
          </div>
        </div>
        <img src="../../assets/home/lunbo(3)_m.webp" alt="" :style="{ width: `${windowWidth}px` }" />
      </div>
      <div class="img-wrapper">
        <div class="xuanfu" :class="{ show: xuanfu[4] }">
          <div class="xuanfuch">
            <div class="xuanfutit">{{ langconf('光学精密测量服务') }}</div>
            <div class="learnabout">{{ langconf('了解详情') }} ></div>
          </div>
        </div>
        <img src="../../assets/home/lunbo(4).webp" alt="" :style="{ width: `${windowWidth}px` }" />
      </div>
      <div class="img-wrapper">
        <div class="xuanfu" :class="{ show: xuanfu[5] }">
          <div class="xuanfuch">
            <div class="xuanfutit">{{ langconf('光学设计服务') }}</div>
            <div class="learnabout">{{ langconf('了解详情') }} ></div>
          </div>
        </div>
        <img src="../../assets/home/lunbo(5).webp" alt="" :style="{ width: `${windowWidth}px` }" />
      </div>
      <div class="img-wrapper">
        <img src="../../assets/home/lunbo(1)_m.webp" alt="" :style="{ width: `${windowWidth}px` }" />
      </div>
    </div>
    <div class="circle-list">

      <div class="circle" v-for="(circle, index) in circles" :key="index" :class="{ active: index === circleIndex }"
        :data-n="index" @click="selectImage(index)"></div>

    </div>
  </div>
</template>
    
<script>

import { useStore } from 'vuex';
import { computed } from 'vue';




export default {
  setup() {
    const store = useStore();
    const messages = computed(() => store.state.messages);
    const currentLanguage = computed(() => store.state.language);
    const langconf = (key) => {
      return messages.value[currentLanguage.value][key];
    };
    return {
      langconf,
    }
  },

  data() {
    return {
      touchStartX: 0,
      touchEndX: 0,
      touchThreshold: 50,
      currentIndex: 0,
      circleIndex: 0,
      circles: [],
      translateX: 0,
      autoplay: null,
      windowWidth: 0,
      arrowsVisible: false,
      timer: null,
      tranitme: 0,
      lock: false,
      xuanfu: {
        '1': false,
        '2': false,
        '3': false,
        '4': false,
        '5': false,
      },
    };
  },

  created() {
    this.circles = new Array(5).fill("");
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    window.addEventListener("resize", this.handleWindowResize);
    this.startAutoplay();
    this.updatexuanfu();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
    this.stopAutoplay();
  },
  methods: {
    emememem() { },
    touchStart(event) {
      this.touchStartX = event.touches[0].clientX;

    },
    touchMove(event) {
      this.touchEndX = event.touches[0].clientX;
    },
    touchEnd() {
      if (this.touchStartX != 0 && this.touchEndX != 0) {
        this.stopAutoplay();
        const swipeDistance = this.touchStartX - this.touchEndX;
        if (swipeDistance > this.touchThreshold) {
          this.nextImage();
        } else if (swipeDistance < -this.touchThreshold) {
          this.prevImage();
        }
        this.touchStartX = 0;
        this.touchEndX = 0;
        this.startAutoplay()
      }

    },
    handleWindowResize() {
      this.windowWidth = window.innerWidth;
    },
    prevImage() {
      if (this.lock) return;
      this.tranitme = '0.5s ease';
      this.currentIndex--;
      this.circleIndex = this.currentIndex;
      this.updatexuanfu();
      if (this.currentIndex === -1) {
        this.tranitme = 'none';
        this.currentIndex = 5;
        setTimeout(() => {
          this.tranitme = '0.5s ease';
          this.currentIndex = 4;
          this.circleIndex = this.currentIndex;
          this.updateTranslateX();
        }, 0);
      }
      this.updateTranslateX();
      this.lock = true;
      setTimeout(() => {
        this.lock = false;
      }, 500);
    },
    nextImage() {
      if (this.lock) return;
      this.tranitme = '0.5s ease';
      this.currentIndex++;
      this.circleIndex = this.currentIndex;
      this.updatexuanfu();
      if (this.currentIndex === 5) {
        this.circleIndex = 0;
        setTimeout(() => {
          this.currentIndex = 0;
          this.tranitme = 'none';
          this.updateTranslateX();
        }, 500);
      }
      this.updateTranslateX();
      this.lock = true;
      setTimeout(() => {
        this.lock = false;
      }, 500);
    },
    selectImage(index) {
      this.stopAutoplay();
      this.tranitme = '0.5s ease';
      this.currentIndex = index;
      this.circleIndex = index;
      this.updateTranslateX();
      this.updatexuanfu();
      this.startAutoplay();
    },
    updateTranslateX() {
      this.translateX = -this.currentIndex * 100;
    },

    updatexuanfu() {
      setTimeout(() => {
        this.xuanfu[this.currentIndex + 1] = true;
        Object.entries(this.xuanfu).map(([key]) => {
          if (key !== String(this.currentIndex + 1)) {
            this.xuanfu[key] = false;
          }
        })
        if (this.currentIndex === 5) {
          this.xuanfu[1] = true;
        }
        if (this.currentIndex === -1) {
          this.xuanfu[5] = true;
        }
      }, 500)
    },
    startAutoplay() {
      this.arrowsVisible = false;
      this.timer = setInterval(() => {
        this.nextImage();
      }, 4000);
    },
    stopAutoplay() {
      this.arrowsVisible = true;
      clearInterval(this.timer);
    },
  }
};
</script>
    
<style scoped>
.xuanfutit {
  position: absolute;
  margin-top: 20px;
  margin-left: 20px;
  font-size: 20px;
  width: 70%;
}

.xuanfuch {
  background-color: rgb(255, 255, 255);
  height: 150px;
  width: 300px;
  margin: 0 auto;
  color: #000;
}

.learnabout {
  position: absolute;
  margin-top: 100px;
  margin-left: 20px;
  color: #3A8CFC;
}

.xuanfu {
  position: relative;
  height: 0px;
  top: 150px;
  font-weight: bold;
  z-index: 9;
  cursor: pointer;
  opacity: 0;
}

.xuanfu.show {
  transform: translateY(200px);
  opacity: 0.8;
  transition: 0.5s;
}


#wrap {
  position: relative;
  overflow: hidden;
  margin: 0px auto;
  width: 100%;
  background-color: #ffffff;
  height: 580px;
  z-index: 1;
}

.img-list {
  display: inline-flex;
  transition: none;
}

.img-wrapper {
  flex-shrink: 0;
  width: 100%;
  height: 580px;
  overflow: hidden;
}

.img-wrapper img {
  height: 100%;
  object-fit: cover;
}



#wrap .img-list {
  display: flex;
  position: relative;
  left: 0px;
  width: 100%;
  height: 100%;
}

#wrap .img-list img {
  height: 100%;
}

#wrap span {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  display: block;
  width: 48px;
  height: 96px;
  user-select: none;
  text-align: center;
  line-height: 70px;
  cursor: pointer;
}

.circle-list {
  display: flex;
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  /* width: 100%; */
  height: 2px;
  z-index: 3;
}

.circle-list>.circle {
  position: relative;
  margin: 0 3px 0 3px;
  width: 24px;
  height: 2px;
  cursor: pointer;
  background-color: #92929263;
}

@keyframes banner-active {
  from {
    width: 0px;
  }

  to {
    width: 100%;
  }
}


.circle-list>.circle.active:before {
  content: "";
  position: absolute;
  background-color: #ffffff;
  left: 0;
  height: inherit;
  width: 0px;
  animation: banner-active 4s linear infinite;
}

ul {
  list-style: none;
}
</style>
    