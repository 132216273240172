import { createRouter, createWebHistory } from 'vue-router';

import Home from '../view/pc/homeView'
import productWI from '../view/pc/product_WIView.vue'
import application from '../view/pc/applicaseView.vue'
import contactus from '../view/pc/contactusView.vue'
import notfoud from '../view/pc/notfoundView.vue'
import productGI from '../view/pc/product_GIView.vue'
import productIOL from '../view/pc/product_IOLView.vue'
import productWLI from '../view/pc/product_WLIView.vue'
import productVWI from '../view/pc/product_VWIView.vue'
import recruit from '../view/pc/recruitView.vue'
import new1 from '../news/pc/01_01.vue'
import news from '../view/pc/newsView.vue'
import mHome from '../view/mobile/homeView'
import mnews from '../view/mobile/newsView.vue'
import mnew1 from '../news/mobile/01_01.vue'
import mproductWI from '../view/mobile/product_WIView.vue'
import mapplication from '../view/mobile/applicaseView.vue'
import mcontactus from '../view/mobile/contactusView.vue'
import mnotfoud from '../view/mobile/notfoundView.vue'
import mproductGI from '../view/mobile/product_GIView.vue'
import mproductIOL from '../view/mobile/product_IOLView.vue'
import mproductWLI from '../view/mobile/product_WLIView.vue'
import mproductVWI from '../view/mobile/product_VWIView.vue'
import mrecruit from '../view/mobile/recruitView.vue'



const routespc = [
    {
        path: '/',
        component: Home,
    }
    ,
    {
        path: '/home',
        component: Home,
        name: 'home'
    }
    ,
    {
        path: '/product-WI',
        component: productWI,
        name: 'productWI'
    }
    ,
    {
        path: '/product-GI',
        component: productGI,
        name: 'productGI'
    }
    ,
    {
        path: '/product-WLI',
        component: productWLI,
        name: 'productWLI'
    }
    ,
    {
        path: '/product-VWI',
        component: productVWI,
        name: 'productVWI'
    }
    ,
    {
        path: '/product-IOL',
        component: productIOL,
        name: 'productIOL'
    }
    ,
    {
        path: '/application',
        component: application,
        name: 'application'
    }
    ,
    {
        path: '/contactus',
        component: contactus,
        name: 'contactus'
    }
    ,
    {
        path: '/recruit',
        component: recruit,
        name: 'recruit',
    }
    ,
    {
        path: '/news',
        component: news,
        name: 'news'
    }
    ,
    {
        path: '/notfound',
        component: notfoud,
        name: '404'
    }
    ,
    {
        path: '/:catchAll(.*)',
        component: notfoud,
        name: '404'
    }
    ,
    {
        path: '/new1',
        component: new1,
        name: 'new1'
    }
    ,
    {
        path: '/notfound',
        component: notfoud,
        name: '404'
    }
    ,
    {
        path: '/notfound',
        component: notfoud,
        name: '404'
    }
    ,
    {
        path: '/notfound',
        component: notfoud,
        name: '404'
    }
    ,
    
]

const routesm = [
    {
        path: '/',
        component: mHome,
    }
    ,
    {
        path: '/home',
        component: mHome,
        name: 'mhome',
    }
    ,
    {
        path: '/mnews',
        component: mnews,
        name: 'news'
    }
    ,
    {
        path: '/mnew1',
        component: mnew1,
        name: 'mnew1'
    }
    ,
    {
        path: '/product-WI',
        component: mproductWI,
        name: 'mproductWI',
    }
    ,
    {
        path: '/product-GI',
        component: mproductGI,
        name: 'mproductGI'
    }
    ,
    {
        path: '/product-WLI',
        component: mproductWLI,
        name: 'mproductWLI'
    }
    ,
    {
        path: '/product-VWI',
        component: mproductVWI,
        name: 'mproductVWI'
    }
    ,
    {
        path: '/product-IOL',
        component: mproductIOL,
        name: 'mproductIOL'
    }
    ,
    {
        path: '/application',
        component: mapplication,
        name: 'mapplication'
    }
    ,
    {
        path: '/contactus',
        component: mcontactus,
        name: 'mcontactus'
    }
    ,
    {
        path: '/recruit',
        component: mrecruit,
        name: 'mrecruit',
    }
    ,
    {
        path: '/notfound',
        component: mnotfoud,
        name: 'm404'
    }
    ,
    {
        path: '/:catchAll(.*)',
        component: mnotfoud,
        name: 'm404'
    }

];

var routes = []
if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
    routes = routesm
} else {
    routes = routespc
}


const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 }
    },
});
export default router;