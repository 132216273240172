<template>
    <div class="menudiv">
        <div class="kezi">
            <div>
                <a href="/"><img src="../../assets/widget/logo_white.webp" class="logoimg"></a>
            </div>
            <div class="pcmenu">
                <ul class="menu">
                    <li class="promenu" @mouseover="proisspread = true" @mouseleave="proisspread = false">
                        <div class="promenugroup">
                            <div class="promenudiv">{{ langconf('产品技术') }}</div>
                            <div class="prolist" :class="{ 'show': proisspread }" @click="proisspread = false">
                                <span @click="swichpro('wi')">{{ langconf('WI') }}</span>
                                <span @click="swichpro('gi')">{{ langconf('GI') }}</span>
                                <span @click="swichpro('wli')">{{ langconf('WLI') }}</span>
                                <span @click="swichpro('vwi')">{{ langconf('VWI') }}</span>
                                <span @click="swichpro('iol')">{{ langconf('IOL') }}</span>
                            </div>
                        </div>
                    </li>
                    <li><a @click="swichmenu('app')">{{ langconf('应用案例') }}</a></li>
                    <li><a @click="swichmenu('news')">{{ langconf('新闻中心') }}</a></li>
                    <li><a @click="swichmenu('esg')">ESG</a></li>
                    <li><a @click="swichmenu('contus')">{{ langconf('联系我们') }}</a></li>
                    <li><a @click="swichmenu('recruit')">{{ langconf('招贤纳士') }}</a></li>
                    <div class="changelang" @mouseover="isspread = true" @mouseleave="isspread = false">
                        <button class="langmenu">
                            <span class="showlong" style="font-family: 'YH';">{{ showlang }}</span>
                            <img src="../../assets//widget/down_arrow_wite.webp" alt="" class="down-arrow"
                                :class="{ rotate: isspread }">
                        </button>
                        <div class="langlist" :class="{ 'show': isspread }">
                            <span @click="switchLanguage('zh')">简体</span>
                            <span @click="switchLanguage('zh')">繁体</span>
                            <span @click="switchLanguage('en')">EN</span>
                        </div>
                    </div>
                </ul>
            </div>
        </div>
    </div>
</template>
    
<script>
import { ref, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { computed } from 'vue';
import router from '../../tools/router';

export default {
    setup() {
        const isMenuOpen = ref(false);
        const mobileProMenuOpen = ref(false);
        const mobileLangMenuOpen = ref(false);
        const windowWidth = ref(window.innerWidth);

        const updateWindowWidth = () => {
            windowWidth.value = window.innerWidth;
        };

        onMounted(() => {
            window.addEventListener('resize', updateWindowWidth);
        });

        onUnmounted(() => {
            window.removeEventListener('resize', updateWindowWidth);
        });

        const toggleMenu = () => {
            isMenuOpen.value = !isMenuOpen.value;
        };

        const toggleMobileProMenu = () => {
            mobileProMenuOpen.value = !mobileProMenuOpen.value;
        };

        const toggleMobileLangMenu = () => {
            mobileLangMenuOpen.value = !mobileLangMenuOpen.value;
        };

        const showlang = ref('');
        const store = useStore();
        const isspread = ref(false);
        const proisspread = ref(false);
        const messages = computed(() => store.state.messages);
        const currentLanguage = computed(() => store.state.language);

        if (currentLanguage.value == 'zh') {
            showlang.value = '简';
        } else if (currentLanguage.value == 'en') {
            showlang.value = 'en';
        }

        const langconf = (key) => {
            return messages.value[currentLanguage.value][key];
        };

        const switchLanguage = (lang) => {
            store.commit('setLanguage', lang);
            localStorage.setItem('language', lang);

            if (lang == 'zh') {
                showlang.value = '简';
            } else if (lang == 'en') {
                showlang.value = 'en';
            }
        };

        const swichpro = (key) => {
            if (key == 'wi') {
                router.push('/product-WI');
            }
            if (key == 'gi') {
                router.push('/product-GI');
            }
            if (key == 'wli') {
                router.push('/product-WLI');
            }
            if (key == 'vwi') {
                router.push('/product-VWI');
            }
            if (key == 'iol') {
                router.push('/product-IOL');
            }
        };

        const swichmenu = (key) => {
            if (key == 'pro') {
                router.push('/product');
            }
            if (key == 'app') {
                router.push('/application');
            }
            if (key == 'news') {
                router.push('/news');
            }
            if (key == 'esg') {
                router.push('/esg');
            }
            if (key == 'contus') {
                router.push('/contactus');
            }
            if (key == 'recruit') {
                router.push('/recruit');
            }
        };

        return {
            langconf,
            switchLanguage,
            isspread,
            proisspread,
            showlang,
            swichmenu,
            swichpro,
            windowWidth,
            isMenuOpen,
            toggleMenu,
            toggleMobileProMenu,
            toggleMobileLangMenu,
            mobileProMenuOpen,
            mobileLangMenuOpen,
        };
    },
};
</script>
    
<style scoped>
.promenudiv {
    font-weight: 600;
}

.promenu:hover {
    color: #ffffff;
}

.promenu:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    transform: scale(0);
    transition: 0.3s;
    z-index: -1;
}


.promenu:hover:after {
    transform: scale(1);
}

.promenu:before {
    content: '';
    top: -1px;
    left: 0;
    position: absolute;
    width: 100%;
    height: calc(100% + 1px);
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    transform: scaleY(0.1);
    opacity: 0;
    transition: 0.5s;
    z-index: -1;
}

.promenu:hover:before {
    transform: scaleY(1.3);
    opacity: 1;
}

.promenugroup {
    position: relative;
    width: 100%;
    display: 1;
}


.promenu {
    position: relative;
    font-size: 16px;
    padding: 5px 20px;
    z-index: 100;
    color: #ffffff;
}

.prolist {
    opacity: 0;
    border-radius: 4px;
    transform: translateY(-30px);
    transition: opacity 0.5s, transform 0.5s, visibility 0s linear 0.5s;
    visibility: hidden;
    position: absolute;
    background-color: rgb(255, 255, 255);
    width: 200%;
    margin: 20px -45%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 100;
}


.prolist.show {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    transition-delay: 0s;
}

.prolist span {
    color: rgb(0, 0, 0);
    padding: 12px 16px;
    text-decoration: none;
    cursor: pointer;
    display: block;
    transition: 0.3s;
}

.prolist span:hover {
    background-color: #d6d6d68b;
    transition: 0.3s;
}

.promenu:hover .prolist {
    display: block;
}


.kezi {
    margin: 0 auto;
    width: 1280px;
    position: relative;
}

.menudiv {
    width: 100%;
    height: 80px;
    position: absolute;
    background-color: #ffffff00;
    position: relative;
    justify-content: center;
    background-repeat: no-repeat;
    animation: 10s moveAndPauseAnimation infinite;
    z-index: 999;
}

.menudiv.black {
    background-color: #3D3D3D;
}

.logoimg {
    position: absolute;
    width: 200px;
    top: 20px;
}

.menu {
    right: 0px;
    position: absolute;
    display: flex;
    align-items: center;
    float: right;
}

.menu li {
    list-style: none;
}

.menu li a {
    position: relative;
    text-transform: capitalize;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    padding: 10px 20px;
    z-index: 100;
    cursor: pointer;
}

.menu li a:hover {
    color: #ffffff;
}

.menu li a:before {
    content: '';
    top: -1px;
    left: 0;
    position: absolute;
    width: 100%;
    height: calc(100% + 1px);
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    transform: scaleY(0.1);
    opacity: 0;
    transition: 0.5s;
    z-index: -1;
}

.menu li a:hover::before {
    transform: scaleY(1.3);
    opacity: 1;
}


.menu li a:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    transform: scale(0);
    transition: 0.5s;
    z-index: -1;
}


.menu li a:hover:after {
    transform: scale(1);
}

.langmenu {
    background-color: #00000000;
    color: hsl(0, 0%, 100%);
    font-size: 18px;
    width: 80px;
    height: 40px;
    border: none;
    cursor: pointer;
    text-align: center;
    border-radius: 4px;
    border: 1px solid #ffffff;
    z-index: 100;
}

.langlist {
    opacity: 0;
    border-radius: 4px;
    transform: translateY(-20px);
    transition: opacity 0.5s, transform 0.5s, visibility 0s linear 0.5s;
    visibility: hidden;
    position: absolute;
    background-color: #000000;
    width: 80px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 100;
}

.langlist.show {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    transition-delay: 0s;
}

.langlist span {
    color: rgb(255, 255, 255);
    padding: 12px 16px;
    text-decoration: none;
    cursor: pointer;
    display: block;
}

.langlist span:hover {
    background-color: #ffffff;
    color: #000;
    border-radius: 5px;
}

.changelang:hover .langlist {
    display: block;
}

.changelang {
    position: relative;
    align-items: center;
    padding: 5px 20px;
    z-index: 100;
}

.showlang {
    color: #000;
    float: left;
    margin-left: 10px;
}

.down-arrow {
    float: right;
    margin-right: 10px;
    margin-top: 2px;
    width: 20px;
    transition: transform 0.5s;
}

.down-arrow.rotate {
    transform: rotate(180deg);
}
</style>