<template>
    <div style="background-color: #3D3D3D; height: 40px; width: 100%; position: fixed; "></div>
    <div>
        <div class="div404">
            <div class="text404">
                <div class="ab404">
                    {{ langconf('页面建设') }}
                </div>
                <img src="../../assets/404/404.webp" alt="">
            </div>
            <div class="backhome404but">
                <RouterLink to="/home">
                    <div class="backhome404" @click="backhome">
                        <div class="backhometxt">{{ langconf('返回首页') }}</div>
                    </div>
                </RouterLink>
            </div>
        </div>
    </div>
</template>
<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
export default {
    setup() {
        const store = useStore();
        const messages = computed(() => store.state.messages);
        const currentLanguage = computed(() => store.state.language);
        const langconf = (key) => {
            return messages.value[currentLanguage.value][key];
        };
        return {
            langconf,
        };
    },
};

</script>
<style scoped>
.ab404 {
    width: 60%;
}


.div404 {
    width: 90%;
    height: 100%;
    margin: 0px auto;
    position: relative;
}

.backhome404 {
    position: relative;
    width: 200px;
    height: 50px;
    border: 0;
    margin: 0 auto;
    border-radius: 5px;
    background-color: rgb(176, 108, 239);
    cursor: pointer;
}

.backhometxt {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
}

.backhome404but {
    margin: 80px auto;
    text-align: center;
}

.text404 {
    height: 300px;
    position: relative;
    background-repeat: no-repeat;
    background-size: 100%;
    margin: 0 auto;
}

.text404 div {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    color: #000000;
    text-align: center;
    z-index: 3;
}

.text404 img {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}
</style>